import React from "react";
import PatientTable from "../molecules/PatientTable";
import { useState } from "react";
import BPChart from "../molecules/BPChart";
import { ClientUser } from "@oben-core-web/models/client-user";

const Patients = () => {
  const [selectedPatient, setSelectedPatient] = useState<ClientUser>();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: ".5rem"
      }}
    >
      <BPChart patientId={selectedPatient?.uid} />
      <PatientTable selectPatient={setSelectedPatient} />
    </div>
  );
};

export default Patients;
