import { object, string, number, date } from 'yup';

const userNameSchema = object({
  first: string().nullable().optional(),
  last: string().nullable().optional(),
  display: string().nullable().optional(),
});

const appointmentSchema = object({
  id: string().optional(),
  startDateTime: date().required(),
  length: number().required(),
  pharmacistId: string().required(),
  barbershopId: string().required(),
  barberId: string().required(),
  clientId: string().required(),
  clientName: userNameSchema,
  barberName: userNameSchema,
});

export default appointmentSchema;
