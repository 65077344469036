import { DocumentSnapshot } from 'firebase/firestore';

// import { BarberAvatar, BarberAvatarExtension } from 'oben_images/oben_images'; // TODO: Uncomment this line after creating the BarberAvatar class
import { BarberUserService } from '../services/barber-user-service';
import { AppInfo } from './app-info';
import { StripeRemediationItem } from './stripe-remediation-item';
import { SystemInfo } from './system-info';
import { UserName } from './user-name';

export enum StripeStatus {
  Unknown = 'Unknown',
  Started = 'Started',
  RemediationNeeded = 'RemediationNeeded',
  TransfersEnabled = 'TransfersEnabled',
  Disabled = 'Disabled',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class StripeStatusHelper {
  static valueOf(searchString: string | null): StripeStatus | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(StripeStatus).includes(searchString as StripeStatus)) //
      ? searchString as StripeStatus
      : null;
  }
}

export interface IBarberUserData {
  // common fields for all "users"
  uid: string;
  name: UserName;
  email: string;
  phoneNumber: string | null;
  enabled: boolean;
  pwdNeedsReset: boolean;
  // mobile-specific fields
  appInfo: AppInfo;
  systemInfo: SystemInfo;
  // barber-specific fields
  // avatar: BarberAvatar; // TODO: Uncomment this line after creating the BarberAvatar class
  currentshopId: string; // shop where barber is currently working (or last worked)
  // workplaces: BarberWorkplace[]; // TODO: Uncomment this line after creating the BarberWorkplace class
  placebasedCareProviderIds: string[];
  stripeStatus: StripeStatus;
  stripeRemediationItems: StripeRemediationItem[];
  serviceFee: number | null;
  noShowFee: number | null;
  cancelFee: number | null;
  cancelWindow: number | null;
}

export class BarberUser {
  uid: string;
  name: UserName;
  email: string;
  phoneNumber: string | null;
  enabled: boolean;
  pwdNeedsReset: boolean;
  // mobile-specific fields
  appInfo: AppInfo;
  systemInfo: SystemInfo;
  // barber-specific fields
  // avatar: BarberAvatar; // TODO: Uncomment this line after creating the BarberAvatar class
  currentshopId: string; // shop where barber is currently working (or last worked)
  // workplaces: BarberWorkplace[]; // TODO: Uncomment this line after creating the Workplace class
  placebasedCareProviderIds: string[];
  stripeStatus: StripeStatus;
  stripeRemediationItems: StripeRemediationItem[];
  serviceFee: number | null;
  noShowFee: number | null;
  cancelFee: number | null;
  cancelWindow: number | null;
  
  constructor({
    uid,
    name,
    email,
    phoneNumber,
    enabled,
    pwdNeedsReset,
    appInfo,
    systemInfo,
    // avatar, // TODO: Uncomment this line after creating the BarberAvatar class
    currentshopId,
    // workplaces, // TODO: Uncomment this line after creating the Workplace class
    placebasedCareProviderIds,
    stripeStatus,
    stripeRemediationItems,
    serviceFee,
    noShowFee,
    cancelFee,
    cancelWindow,
  }: IBarberUserData) {
    this.uid = uid;
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.enabled = enabled;
    this.pwdNeedsReset = pwdNeedsReset;
    this.appInfo = appInfo;
    this.systemInfo = systemInfo;
    // this.avatar = avatar; // TODO: Uncomment this line after creating the BarberAvatar class
    this.currentshopId = currentshopId;
    // this.workplaces = workplaces; // TODO: Uncomment this line after creating the Workplace class
    this.placebasedCareProviderIds = placebasedCareProviderIds;
    this.stripeStatus = stripeStatus;
    this.stripeRemediationItems = stripeRemediationItems;
    this.serviceFee = serviceFee;
    this.noShowFee = noShowFee;
    this.cancelFee = cancelFee;
    this.cancelWindow = cancelWindow;
  }

  get isOnboardingComplete(): boolean {
    if ((this.name.display == null) || (this.name.first == null) || (this.name.last == null) ||
      (this.stripeStatus !== StripeStatus.TransfersEnabled)) {
      return false;
    } else {
      return true;
    }
  }

  async updateDb(): Promise<void> {
    const db = new BarberUserService();
    await db.updateBarberUser(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): BarberUser {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error('Document data is undefined');
    return BarberUser.fromMap(docSnap.id, data);
  }

  static fromMap(uid: string, data: { [key: string]: any }): BarberUser {
    const name: UserName = UserName.fromMap(data["name"] ?? {});
    const email: string = data["email"] ?? "";
    const phoneNumber: string | null = data["phoneNumber"] ?? null;
    const enabled: boolean = data["enabled"] ?? false;
    const pwdNeedsReset: boolean = data["pwdNeedsReset"] ?? true;
    const appInfo: AppInfo = AppInfo.fromMap(data["appInfo"] ?? {});
    const systemInfo: SystemInfo = SystemInfo.fromMap(data["systemInfo"] ?? {});
    // const avatar: BarberAvatar = BarberAvatarHelper.valueOf(data["avatar"] ?? "") ?? BarberAvatar.zero; // TODO: Uncomment this line after creating the BarberAvatar class
    const currentshopId: string = data["currentshopId"] ?? "";
    // const workplaces: BarberWorkplace[] = (data["workplaces"])
    //   ? data["workplaces"].map((element: any) => BarberWorkplace.fromMap(element))
    //   : [];
    const placebasedCareProviderIds: string[] = data["placebasedCareProviderIds"] ?? [];
    const stripeStatus: StripeStatus = StripeStatusHelper.valueOf(data["stripeStatus"] ?? "") ?? StripeStatus.Unknown;
    const stripeRemediationItems: StripeRemediationItem[] = (data["stripeRemediationItems"])
      ? data["stripeRemediationItems"].map((element: any) => StripeRemediationItem.fromMap(element))
      : [];
    const serviceFee: number | null = data["serviceFee"];
    const noShowFee: number | null = data["noShowFee"];
    const cancelFee: number | null = data["cancelFee"];
    const cancelWindow: number | null = data["cancelWindow"];
    return new BarberUser({
      uid: uid,
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      enabled: enabled,
      pwdNeedsReset: pwdNeedsReset,
      appInfo: appInfo,
      systemInfo: systemInfo,
      // avatar: avatar, // TODO: Uncomment this line after creating the BarberAvatar class
      currentshopId: currentshopId,
      // workplaces: workplaces, // TODO: Uncomment this line after creating the Workplace class
      placebasedCareProviderIds: placebasedCareProviderIds,
      stripeStatus: stripeStatus,
      stripeRemediationItems: stripeRemediationItems,
      serviceFee: serviceFee,
      noShowFee: noShowFee,
      cancelFee: cancelFee,
      cancelWindow: cancelWindow,
    });
  }

  toJson(): { [key: string]: any } {
    return {
        // "uid": this.uid, // not necessary to save UID (it is already part of the Firestore document)
      "name": this.name.toJson(),
      "email": this.email,
      "phoneNumber": this.phoneNumber,
      "enabled": this.enabled,
      "pwdNeedsReset": this.pwdNeedsReset,
      "appInfo": this.appInfo.toJson(),
      "systemInfo": this.systemInfo.toJson(),
      // "avatar": this.avatar, // TODO: Uncomment this line after creating the BarberAvatar class
      "currentshopId": this.currentshopId,
      // "workplaces": this.workplaces.map((element) => element.toJson()), // TODO: Uncomment this line after creating the Workplace class
      "placebasedCareProviderIds": this.placebasedCareProviderIds,
      "stripeStatus": this.stripeStatus,
      "stripeRemediationItems": this.stripeRemediationItems.map((element) => element.toJson()),
      "serviceFee": this.serviceFee,
      "noShowFee": this.noShowFee,
      "cancelFee": this.cancelFee,
      "cancelWindow": this.cancelWindow,
    };
  }
}