import useCurrentUser from "@/hooks/useCurrentUser";
import { useState } from "react";
import UserCard from "../molecules/UserProfileCard";
import { useParams } from "react-router-dom";
import UserProfileForm from "../molecules/CurrentUserProfileForm";
import useWebUser from "@/hooks/useWebUser";
import WorkingHourAdjustments from "@components/organisms/WorkingHourAdjustments";

const UserProfile = () => {
  const { uid } = useParams();
  const { webUser } = useCurrentUser();
  const { user } = useWebUser(uid);
  const [formOpen, setFormOpen] = useState(false);
  const canEdit = !!(user && webUser) && user.uid === webUser.uid;

  return (
    <div>
      {formOpen
        ? canEdit && (
            <UserProfileForm
              user={user}
              onToggleForm={() => setFormOpen((o) => !o)}
            />
          )
        : user && (
            <>
              <UserCard
                user={user}
                canEdit={canEdit}
                onToggleForm={() => setFormOpen((o) => !o)}
              />
              <WorkingHourAdjustments canEdit={canEdit} user={user} />
            </>
          )}
    </div>
  );
};

export default UserProfile;
