import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Card,
} from '@chakra-ui/react';
import loginSchema from '@resolvers/login';
import useAuthentication from '@/hooks/useAuthentication';

interface LoginFormInputs {
  email: string;
  password: string;
}

const Login = () => {
  const { login } = useAuthentication();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (data: LoginFormInputs) => {
    console.log({ data });
    login(data);
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Heading as='h1'>Login</Heading>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <Input placeholder='Email' type='email' {...register('email')} />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.password}>
            <FormLabel>Password</FormLabel>
            <Input
              placeholder='Password'
              type='password'
              {...register('password')}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
          <Button type='submit' colorScheme='blue'>
            Log in
          </Button>
        </Box>
      </form>
    </Card>
  );
};

export default Login;
