import { useEffect, useState } from "react";
import { BpReadingService } from "@oben-core-web/services/bp-reading-service";
import { BpReading } from "@oben-core-web/models/bp-reading";
import { DateTime } from "luxon";

const useBpReadings = (clientUserId?: string) => {
  const [bpReadings, setBpReadings] = useState<BpReading[]>([]);

  useEffect(() => {
    let loading = false;
    const fetchBpReadings = async () => {
      if (!loading && !!clientUserId) {
        const bpReadingService = new BpReadingService();
        const bpReadings = await bpReadingService.getClientBpReadings(
          clientUserId
        );
        setBpReadings(
          bpReadings.sort((a: any, b: any) => {
            return DateTime.fromJSDate(a.readingDate) <
              DateTime.fromJSDate(b.readingDate)
              ? -1
              : 1;
          })
        );
      }
    };

    fetchBpReadings();

    return () => {
      loading = true;
    };
  }, [clientUserId]);

  return { bpReadings };
};

export default useBpReadings;
