import { RootState, appSelector } from '@/store';
import { WebUser } from '@oben-core-web/models/web-user';
import { WebUserService } from '@oben-core-web/services/web-user-service';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCurrentUser = () => {
  const currentUser = useSelector(
    (state: RootState) => appSelector(state).currentUser
  );
  const [webUser, setWebUser] = useState<WebUser | null>();
  const uid = currentUser?.uid;
  useEffect(() => {
    if (!uid) return;
    console.log('SET UP CURRENT USER STREAM');
    const { unsubscribe } = new WebUserService().streamWebUser(
      uid,
      (snapshot) => {
        if (snapshot.exists()) {
          setWebUser(WebUser.fromFirestore(snapshot));
        } else {
          setWebUser(null);
        }
      }
    );
    return () => {
      console.log('TEAR DOWN CURRENT USER STREAM');
      unsubscribe && unsubscribe();
    };
  }, [uid]);

  return { webUser };
};

export default useCurrentUser;
