import React, { useMemo } from "react";
import Chart from "react-apexcharts";
import useBpReadings from "@/hooks/useBpReadings";
import useClientCarePlans from "@/hooks/useClientCarePlans";
import {
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { CarePlan } from "@oben-core-web/models/care-plan";
import { CarePlanService } from "@oben-core-web/services/care-plan-service";
import { BpGoal } from "@oben-core-web/models/bp-goal";
import { useForm } from "react-hook-form";
import { BpReading } from "@oben-core-web/models/bp-reading";

interface IBPChart {
  patientId?: string;
  height?: number | string;
  width?: number | string;
}

const BPChart = ({ patientId, ...rest }: IBPChart) => {
  const { isOpen, onToggle } = useDisclosure();
  const { bpReadings } = useBpReadings(patientId);
  const { currentCarePlan } = useClientCarePlans(patientId);

  // put BPReadings and BPGoals into a single list, sort by date
  const sortedData: (BpReading | BpGoal)[] = [
    ...bpReadings,
    ...(currentCarePlan?.bpGoals ? currentCarePlan.bpGoals : [])
  ].sort((a, b) => {
    const aDate = a instanceof BpReading ? a.readingDate : a.setDate;
    const bDate = b instanceof BpReading ? b.readingDate : b.setDate;
    return aDate < bDate ? -1 : 1;
  });

  // fill in gaps where we have a reading that doesn't match a goal date and vice versa
  const combinedSeries = sortedData.reduce(
    (a, c) => {
      if (c instanceof BpReading) {
        const x = Date.parse(c.readingDate.toString());
        a.systolic.push({
          x,
          y: c.systolic
        });
        a.diastolic.push({
          x,
          y: c.diastolic
        });
        const lastGoalSystolicValue =
          a.goalSystolic[a.goalSystolic.length - 1]?.y ?? 0;
        const lastGoalDiastolicValue =
          a.goalDiastolic[a.goalDiastolic.length - 1]?.y ?? 0;
        a.goalSystolic.push({ x, y: Number(lastGoalSystolicValue) });
        a.goalDiastolic.push({ x, y: Number(lastGoalDiastolicValue) });
      } else {
        const x = Date.parse(c.setDate.toString());
        a.goalSystolic.push({ x, y: Number(c.systolic) });
        a.goalDiastolic.push({ x, y: Number(c.diastolic) });
        const lastSystolicValue = a.systolic[a.systolic.length - 1].y ?? 0;
        const lastDiastolicValue = a.diastolic[a.diastolic.length - 1].y ?? 0;
        a.systolic.push({ x, y: lastSystolicValue });
        a.diastolic.push({ x, y: lastDiastolicValue });
      }
      return a;
    },
    {
      systolic: [] as { x: number; y: number }[],
      diastolic: [] as { x: number; y: number }[],
      goalSystolic: [] as { x: number; y: number }[],
      goalDiastolic: [] as { x: number; y: number }[]
    }
  );

  // assign each series to a plotted line or area on the graph
  const chartSeries = useMemo(() => {
    const bpSeries = [
      {
        name: "Systolic",
        data: combinedSeries.systolic
      },
      {
        name: "Diastolic",
        data: combinedSeries.diastolic
      }
    ];
    if (currentCarePlan) {
      const goalSeries = [
        {
          name: "Goal Systolic",
          data: combinedSeries.goalSystolic,
          type: "area"
        },
        {
          name: "Goal Diastolic",
          data: combinedSeries.goalDiastolic,
          type: "area"
        }
      ];
      return bpSeries.concat(goalSeries);
    }
    return bpSeries;
  }, [combinedSeries, currentCarePlan]);
  return (
    <>
      <Chart
        type='line'
        width={"82%"}
        height={"50%"}
        {...rest}
        options={{
          title: {
            text: "Blood Pressure"
          },
          chart: {
            id: "vuechart-example",
            toolbar: {
              show: true,
              tools: {
                download: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
                customIcons: [
                  {
                    icon: '<div style="display: flex; width: 5.5rem; position: absolute; right: 0; font-size:smaller;">Edit BP Goal</div>',
                    index: 0,
                    title: "Edit BP Goal",
                    class: "custom-icon",
                    click: function () {
                      onToggle();
                    }
                  }
                ]
              }
            }
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeFormatter: {
                year: "yyyy",
                month: "MM 'yy",
                day: "M/dd/yy"
              }
            }
            // categories: readingDates
          },
          noData: {
            text: "No blood pressure readings for this patient",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "14px",
              fontFamily: undefined
            }
          },
          stroke: {
            curve: "smooth"
          }
        }}
        series={chartSeries}
      />
      {currentCarePlan && (
        <BPGoalFormModal
          carePlan={currentCarePlan}
          isOpen={isOpen}
          onToggle={onToggle}
        />
      )}
    </>
  );
};

export default BPChart;

const BPGoalFormModal = ({
  carePlan,
  isOpen,
  onToggle
}: {
  carePlan: CarePlan;
  isOpen: boolean;
  onToggle: () => void;
}) => {
  const toast = useToast();
  const { bpGoals } = carePlan;
  const lastBpGoal = bpGoals.sort((a, b) =>
    a.setDate > b.setDate ? -1 : 1
  )[0];
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      systolic: lastBpGoal.systolic,
      diastolic: lastBpGoal.diastolic
    }
  });
  const submissionHandler = async (data: {
    systolic: number;
    diastolic: number;
  }) => {
    const { dirtyFields } = formState;
    if (dirtyFields) {
      const carePlanService = new CarePlanService();
      const newBpGoals = carePlan.bpGoals.concat([
        new BpGoal({
          systolic: data.systolic,
          diastolic: data.diastolic,
          setDate: new Date()
        })
      ]);
      carePlan.bpGoals = newBpGoals;
      await carePlanService
        .updateCarePlan(carePlan)
        .then(() => {
          toast({ status: "success", description: "New BP goal set" });
          onToggle();
        })
        .catch((error) => {
          console.log("Failed to create BP goal", error);
          toast({ status: "error", description: "Failed to set new BP goal" });
        });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(submissionHandler)}>
          <ModalHeader
            display='flex'
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text>Set New Blood Pressure Goal</Text>
            <CloseButton onClick={onToggle} />
          </ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Systolic</FormLabel>
              <Input type='number' {...register("systolic")} />
            </FormControl>
            <FormControl>
              <FormLabel>Diastolic</FormLabel>
              <Input type='number' {...register("diastolic")} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onToggle}>Cancel</Button>
            <Button type='submit'>Save</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
