import React, { useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Text,
  Input,
  Heading,
  Flex,
  useToast
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";
import { DateTime } from "luxon";
import useCustomWorkingHours from "@/hooks/useCustomWorkingHours"; // Adjust the import path as needed
import { WebUser } from "@oben-core-web/models/web-user"; // Adjust the import path as needed
import { CustomWorkingHours } from "@oben-core-web/models/custom-working-hours"; // Adjust the import path as needed
import { CustomWorkingHoursService } from "@oben-core-web/services/custom-working-hours-service"; // Adjust the import path as needed

interface IUserProfileAdjustments {
  canEdit: boolean;
  user: WebUser;
}

interface ICustomWorkingHourData {
  id: string;
  date: string;
  start: string;
  end: string;
  isDayOff: boolean;
}

const UserProfileAdjustments = ({ canEdit, user }: IUserProfileAdjustments) => {
  const toast = useToast();
  const [selectedItem, setSelectedItem] = useState<CustomWorkingHours | null>(
    null
  );
  const {
    isOpen: isDialogOpen,
    onOpen: onDialogOpen,
    onClose: onDialogClose
  } = useDisclosure();
  const {
    isOpen: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose
  } = useDisclosure();

  const { customWorkingHours, fetchCustomWorkingHours } =
    useCustomWorkingHours();
    // {
    //   userId: user?.uid ?? "",
    //   start: DateTime.now().set({ hour: 0, minute: 0, second: 0 }).toJSDate(),
    //   end: DateTime.now()
    //     .plus({ years: 5 })
    //     .set({ hour: 0, minute: 0, second: 0 })
    //     .toJSDate()
    // }

  const workingHourAdjustments: ICustomWorkingHourData[] =
    customWorkingHours.map((cwh: CustomWorkingHours) => ({
      id: cwh.modelId,
      date: DateTime.fromJSDate(cwh.start!).toFormat("M/dd/yy"),
      start: DateTime.fromJSDate(cwh.start!).toFormat("h:mm a"),
      end: DateTime.fromJSDate(cwh.end!).toFormat("h:mm a"),
      isDayOff: cwh.isDayOff
    }));

  useEffect(() => {
    if (user) {
      fetchCustomWorkingHours({
        userId: user?.uid ?? "",
        start: DateTime.now().set({ hour: 0, minute: 0, second: 0 }).toJSDate(),
        end: DateTime.now()
          .plus({ years: 5 })
          .set({ hour: 0, minute: 0, second: 0 })
          .toJSDate()
      });
    }
  }, [user, fetchCustomWorkingHours]);

  const toggleDialog = useCallback(() => {
    if (isDialogOpen) {
      setSelectedItem(null);
    }
    onDialogOpen();
  }, [isDialogOpen, onDialogOpen]);

  const closeDeleteDialog = useCallback(() => {
    setSelectedItem(null);
    onDeleteDialogClose();
  }, [onDeleteDialogClose]);

  const deleteItemConfirm = useCallback(() => {
    if (!selectedItem) {
      console.log("No item was selected");
      return;
    }
    const customWorkingHourService = new CustomWorkingHoursService();
    customWorkingHourService
      .deleteCustomWorkingSlot(selectedItem)
      .then(() => {
        toast({ description: "Adjustment deleted", variant: "Success" });
        setSelectedItem(null);
      })
      .catch((e) => {
        console.log("Encountered error while deleting CustomWorkingHour: ", e);
        toast({ description: "Failed to delete adjustment", variant: "Error" });
      })
      .finally(() => {
        onDeleteDialogClose();
      });
  }, [selectedItem, onDeleteDialogClose, toast]);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      date: "",
      start: "",
      end: "",
      isDayOff: false
    }
  });
  const editItem = useCallback(
    (item: ICustomWorkingHourData) => {
      const customWorkingHour = customWorkingHours.find(
        (cwh: CustomWorkingHours) => cwh.modelId === item.id
      );
      if (!customWorkingHour) {
        console.log("NO CUSTOM WORKING HOUR FOUND");
        return;
      }
      setSelectedItem(customWorkingHour);
      reset({
        date: DateTime.fromJSDate(customWorkingHour.start!).toFormat(
          "yyyy-MM-dd"
        ),
        start: DateTime.fromJSDate(customWorkingHour.start!).toFormat("H:mm"),
        end: DateTime.fromJSDate(customWorkingHour.end!).toFormat("H:mm"),
        isDayOff: customWorkingHour.isDayOff
      });
      onDialogOpen();
    },
    [customWorkingHours, onDialogOpen, reset]
  );

  const deleteItem = useCallback(
    (item: ICustomWorkingHourData) => {
      const customWorkingHour = customWorkingHours.find(
        (cwh: CustomWorkingHours) => cwh.modelId === item.id
      );
      if (!customWorkingHour) {
        console.log("NO CUSTOM WORKING HOUR FOUND");
        return;
      }
      setSelectedItem(customWorkingHour);
      onDeleteDialogOpen();
    },
    [customWorkingHours, onDeleteDialogOpen]
  );

  const onSubmit = async (data: any) => {
    console.log("HANDLE SUBMIT", data);
    // Handle form submission
    const userId = user.uid;
    const date = new Date(data.date);
    const [startHour, startMin] = data.start.split(":");
    const [endHour, endMin] = data.end.split(":");
    const start = DateTime.fromJSDate(date)
      .set({ hour: startHour, minute: startMin })
      .toJSDate();
    const end = DateTime.fromJSDate(date)
      .set({ hour: endHour, minute: endMin })
      .toJSDate();
    const customWorkingHourService = new CustomWorkingHoursService();
    const customWorkingHour = new CustomWorkingHours({
      modelId: selectedItem?.modelId ?? "",
      userId,
      start,
      end,
      isDayOff: data.isDayOff
    });
    if (selectedItem) {
      await customWorkingHourService
        .updateCustomWorkingSlot(customWorkingHour)
        .then(() => {
          toast({
            description: "Schedule adjustment updated!",
            status: "success"
          });
        })
        .catch((e) => {
          console.log("Edit schedule adjustment error:", e);
          toast({
            description: "Failed to update schedule adjustment",
            status: "error"
          });
        });
    } else {
      await customWorkingHourService
        .addCustomWorkingSlot(customWorkingHour)
        .then(() => {
          toast({
            description: "Schedule adjustment created!",
            status: "success"
          });
        })
        .catch((e) => {
          console.log("Create custom working hour error:", e);
          toast({
            description: "Failed to create schedule adjustment",
            status: "error"
          });
        });
    }
  };

  return (
    <Box width='75%' mt={4} p={2}>
      <Heading as='h2' size='lg' mb={4}>
        Schedule Adjustments
      </Heading>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Start</Th>
            <Th>End</Th>
            <Th>All-day</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {workingHourAdjustments.map((item) => (
            <Tr key={item.id}>
              <Td>{item.date}</Td>
              <Td>{item.start !== item.end ? item.start : "-"}</Td>
              <Td>{item.start !== item.end ? item.end : "-"}</Td>
              <Td>
                <Checkbox isChecked={item.isDayOff} isReadOnly />
              </Td>
              {canEdit && (
                <Td>
                  <IconButton
                    aria-label='edit-icon'
                    icon={<EditIcon />}
                    size='sm'
                    mr={2}
                    onClick={() => editItem(item)}
                  />
                  <IconButton
                    aria-label='delete-icon'
                    icon={<DeleteIcon />}
                    size='sm'
                    onClick={() => deleteItem(item)}
                  />
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify='space-between' align='center' mt={4}>
        <Text>
          Create schedule adjustments to modify your working hours on specific
          days.
        </Text>
        {canEdit && (
          <Button
            leftIcon={<AddIcon />}
            colorScheme='blue'
            onClick={toggleDialog}
          >
            New Adjustment
          </Button>
        )}
      </Flex>

      <Modal isOpen={isDialogOpen} onClose={onDialogClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Working Hour Adjustment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* WorkingHourAdjustmentsForm can be another component */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name='date'
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder='Date' type={"date"} />
                )}
              />
              <Controller
                name='start'
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder='Start Time' type={"time"} />
                )}
              />
              <Controller
                name='end'
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder='End Time' type={"time"} />
                )}
              />
              <Controller
                name='isDayOff'
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} value={JSON.stringify(field.value)}>
                    All-day
                  </Checkbox>
                )}
              />
              <Button type='submit' colorScheme='blue'>
                Save
              </Button>
              <Button colorScheme='blue' onClick={onDialogClose}>
                Close
              </Button>
            </form>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeleteDialogOpen} onClose={closeDeleteDialog}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this item?</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={closeDeleteDialog}>
              Cancel
            </Button>
            <Button colorScheme='red' onClick={deleteItemConfirm}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UserProfileAdjustments;
