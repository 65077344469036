import { DateTime } from "luxon";

export const convertToLocalTZ = (date: Date) => {
  const luxonDate = DateTime.fromJSDate(date).toLocal();
  return luxonDate.toJSDate();
};

export const convertToUTC = (date: Date) => {
  return DateTime.fromJSDate(date).toUTC().toJSDate();
};
