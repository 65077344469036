import {
  CollectionReference,
  collection,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  or,
  QuerySnapshot
} from "firebase/firestore";
import { Database } from "../../firebase";

import { UserType } from "../constants/core-enums";
import { WebUser } from "../models/web-user";
import { GaLog } from "../utils/ga-log";

export class WebUserService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "webUsers";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get a stream of a single WebUser's profile
  streamWebUser(
    uid: string,
    handleSnapshot: (docSnap: DocumentSnapshot) => void = (
      docSnap: DocumentSnapshot
    ) => {
      if (docSnap.exists()) {
        return WebUser.fromFirestore(docSnap);
      } else {
        return WebUser.fromMap("", {});
      }
    }
  ): {
    unsubscribe: (() => void) | undefined;
  } {
    let unsubscribe: (() => void) | undefined;
    try {
      if (uid) {
        const docRef = doc(this._collectionReference, uid);
        unsubscribe = onSnapshot(
          docRef,
          (docSnap) => {
            handleSnapshot(docSnap);
            GaLog.readDocument(this._collectionReference.path, docSnap.id, {
              isSubscription: true
            });
          },
          (error) => {
            console.error("Error in onSnapshot:", error);
            // TODO: Do we need to throw an error here?
            GaLog.readDocument(this._collectionReference.path, uid, {
              isSubscription: true
            });
          }
        );
      }
      return { unsubscribe };
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error, {
        isSubscription: true
      });
      throw error;
    }
  }

  // TODO: include filter on ProgramId or some other identifier so that teams don't have visibility into staff in other programs.
  async getAllWebUsers(userTypeFilters?: UserType[]): Promise<WebUser[]> {
    let qSnapshot: QuerySnapshot<DocumentData>;
    try {
      if (userTypeFilters?.length) {
        const filters =
          userTypeFilters?.map((type) => {
            return where("userType", "==", type);
          }) ?? [];
        qSnapshot = await getDocs(
          query(this._collectionReference, or(...filters))
        );
      } else {
        qSnapshot = await getDocs(this._collectionReference);
      }
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        WebUser.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // confirm that a WebUser exists
  async validateWebUser(uid: string): Promise<boolean> {
    try {
      const docRef = doc(this._collectionReference, uid);
      const docSnap = await getDoc(docRef);
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return docSnap.exists();
    } catch (error) {
      console.log({ error });
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // NOTE:  Not extensively used by Web Portal app (Instead, data is retrieved via streamWebUser() above).
  async getWebUser(uid: string): Promise<WebUser> {
    try {
      const docRef = doc(this._collectionReference, uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`WebUser not found: ${uid}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return WebUser.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addWebUser(webUser: WebUser): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, webUser.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateWebUser(webUser: WebUser): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, webUser.uid);
      await updateDoc(docRef, webUser.toJson());
      GaLog.updateDocument(this._collectionReference.path, webUser.uid);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // async deleteWebUser(uid: string): Promise<void> {
  //   try {
  //     const docRef = doc(this._collectionReference, uid);
  //     await deleteDoc(docRef);
  //     GaLog.deleteDocument(this._collectionReference.path, uid);
  //     return;
  //   } catch (error) {
  //     GaLog.deleteError(this._collectionReference.path, error);
  //     throw error;
  //   }
  // }
}
