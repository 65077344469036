import { Medication } from "@oben-core-web/models/medication";
import { MedicationService } from "@oben-core-web/services/medication-service";
import { useEffect, useState, useCallback } from "react";

const useMedications = ({
  clientId,
  carePlanId
}: {
  carePlanId: string;
  clientId: string;
}) => {
  const [medications, setMedications] = useState<Medication[]>([]);
  const fetchMedications = useCallback(async () => {
    if (carePlanId && clientId) {
      console.log("CALL FETCH MEDICATIONS");
      const medicationService = new MedicationService();
      const meds = await medicationService.getClientMedications(
        clientId,
        carePlanId
      );
      setMedications(meds);
    }
  }, [carePlanId, clientId]);
  useEffect(() => {
    fetchMedications();
  }, [fetchMedications]);
  return { medications, refetch: fetchMedications };
};

export default useMedications;
