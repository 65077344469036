import { DocumentSnapshot } from 'firebase/firestore';

import { MedicationBaseService } from '../services/medication-base-service';
import { MedDosage } from './med-dosage';

// This class is used to represent a table of "base" tasks (which are provider-specific) 
// that can be assigned to clients. When one of these tasks is assigned to a client, a new
// Medication is created with the same data as the MedicationBase, but with the addition of
// client-specific data (e.g. due date, status, etc.)
export interface IMedicationBaseData {
  id: string;
  placeBasedCareProvId: string;
  name: string;
  defaultDosage: MedDosage;
  enabled: boolean;
}

export class MedicationBase {
  id: string;
  placeBasedCareProvId: string;
  name: string;
  defaultDosage: MedDosage;
  enabled: boolean;

  constructor({ 
    id, 
    placeBasedCareProvId,
    name,
    defaultDosage,
    enabled,
  }: IMedicationBaseData) {
    this.id = id;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.name = name;
    this.defaultDosage = defaultDosage;
    this.enabled = enabled;
  }
  async updateDb(): Promise<void> {
    const db = new MedicationBaseService();
    await db.updateMedicationBase(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): MedicationBase {
    const data = docSnap.data() as { [key: string]: any };
    return MedicationBase.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): MedicationBase {
    return new MedicationBase({
      id: id,
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      name: data["name"] ?? "",
      defaultDosage: MedDosage.fromMap(data["defaultDosage"] ?? {}),
      enabled: data["enabled"] ?? false,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      "placeBasedCareProvId": this.placeBasedCareProvId,
      "name": this.name,
      "defaultDosage": this.defaultDosage.toJson(),
      "enabled": this.enabled,
    };
  }
}