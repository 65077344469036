import {
  collection,
  doc,
  getDoc,
  getDocs,
  runTransaction
} from "firebase/firestore";
import { Database } from "../../firebase";

import { CarePlan, PlanStatus } from "../models/care-plan";
import { ClientTask } from "../models/client-task";
import { ClientNote, ClientNoteType } from "../models/client-note";
import { Medication } from "../models/medication";
import { BpGoal } from "@oben-core-web/models/bp-goal";
import { MedicationBase } from "@oben-core-web/models/medication-base";
import { Appointment } from "@oben-core-web/models/appointment";
import { MedAdherence } from "@oben-core-web/models/med-adherence";

// TODO: pass carePlanId to each static func instead of entire care plan model
export class CarePlanTransactionService {
  static async saveBpGoalAndRiskFactors({
    clientId,
    carePlan,
    bpGoal,
    isSmoker,
    hasDiabetes
  }: {
    clientId: string;
    carePlan: CarePlan;
    bpGoal: BpGoal;
    isSmoker: boolean;
    hasDiabetes: boolean;
  }) {
    if (!clientId || !carePlan || !bpGoal) {
      throw new Error("Missing required parameters");
    }
    try {
      await runTransaction(Database, async (transaction) => {
        const carePlanRef = doc(
          Database,
          `clients/${clientId}/carePlans/${carePlan.modelId}`
        );
        const carePlanDoc = CarePlan.fromFirestore(await getDoc(carePlanRef));

        if (!carePlanDoc) {
          throw new Error("Could not fetch care plan");
        }
        // sort bpGoals by setDate desc
        const existingbpGoals = carePlanDoc.bpGoals.sort((a, b) =>
          a.setDate >= b.setDate ? -1 : 1
        );
        // compare provided to last bpGoal
        const lastBpGoal = existingbpGoals[0];
        if (
          !lastBpGoal ||
          lastBpGoal.systolic !== bpGoal.systolic ||
          lastBpGoal.diastolic !== bpGoal.diastolic
        ) {
          // if there isn't a last bpGoal or if provided value is different, update careplan with new bpGoal
          await transaction.update(carePlanRef, {
            bpGoals: [
              ...existingbpGoals.map((bpg) => bpg.toJson()),
              { ...bpGoal.toJson() }
            ],
            isDiabetic: hasDiabetes,
            isSmoker
          });
        }
      });
    } catch (e) {
      console.log("Error saving care plan BP goal and risk factors", e);
      throw e;
    }
  }

  static async saveMedsAndAdherence({
    clientId,
    carePlanId,
    currentMedications,
    unlistedMedications
  }: {
    clientId: string;
    carePlanId: string;
    currentMedications: {
      medication: Medication;
      adherence: MedAdherence;
      note?: ClientNote;
    }[];
    unlistedMedications: {
      medication: Medication;
      adherence: MedAdherence;
      note?: ClientNote;
    }[];
  }) {
    if (!clientId || !carePlanId) {
      throw new Error("Missing required parameters");
    }
    try {
      await runTransaction(Database, async (transaction) => {
        const carePlanRef = doc(
          Database,
          `clients/${clientId}/carePlans/${carePlanId}`
        );
        const carePlanDoc = CarePlan.fromFirestore(await getDoc(carePlanRef));

        if (!carePlanDoc) {
          throw new Error("Could not fetch care plan");
        }

        // create currentMedications list
        const medicationCollectionRef = collection(
          Database,
          `clients/${clientId}/carePlans/${carePlanId}/medications`
        );
        const adherenceCollectionRef = collection(
          Database,
          `clients/${clientId}/carePlans/${carePlanId}/medAdherences`
        );
        const noteCollectionRef = collection(
          Database,
          `clients/${clientId}/clientNotes`
        );

        const existingMedications = (
          await getDocs(medicationCollectionRef)
        ).docs.map((d) => Medication.fromFirestore(d));

        for (const row of currentMedications) {
          // compare provided medication against list of existingMedications to remove duplicates
          const isDuplicate = existingMedications.some(
            (existingMed) =>
              existingMed.medBaseId === row.medication.medBaseId &&
              existingMed.newDosage.strength ===
                row.medication.newDosage.strength &&
              existingMed.newDosage.durationCount ===
                row.medication.newDosage.durationCount &&
              existingMed.newDosage.frequencyCount ===
                row.medication.newDosage.frequencyCount &&
              existingMed.newDosage.frequencyPeriod ===
                row.medication.newDosage.frequencyPeriod
          );
          // if item isn't a duplicate, add the medication
          if (!isDuplicate) {
            const medicationDoc = doc(medicationCollectionRef);
            await transaction.set(medicationDoc, {
              ...row.medication.toJson(),
              modelId: medicationDoc.id,
              carePlanId
            });
            const adherenceDoc = doc(adherenceCollectionRef);
            await transaction.set(adherenceDoc, {
              ...row.adherence.toJson(),
              modelId: adherenceDoc.id,
              medicationId: medicationDoc.id,
              carePlanId
            });
            if (row.note) {
              const noteDoc = doc(noteCollectionRef);
              await transaction.set(noteDoc, {
                ...row.note.toJson(),
                modelId: noteDoc.id,
                noteSourceId:
                  row.note.noteType === ClientNoteType.Medication
                    ? medicationDoc.id
                    : row.note.noteType === ClientNoteType.MedAdherence
                    ? adherenceDoc.id
                    : ""
              });
            }
          }
        }

        const medBaseRef = collection(Database, "medicationBases");
        const existingMedBases = (await getDocs(medBaseRef)).docs.map((mb) =>
          MedicationBase.fromFirestore(mb)
        );

        for (const row of unlistedMedications) {
          const isDuplicate = existingMedBases.some(
            (emb) =>
              emb.name === row.medication.name &&
              emb.placeBasedCareProvId === row.medication.placeBasedCareProvId
          );
          if (!isDuplicate) {
            // create new med base
            const medBaseDoc = doc(medBaseRef);
            const newMedBase = new MedicationBase({
              id: medBaseDoc.id,
              placeBasedCareProvId: row.medication.placeBasedCareProvId,
              name: row.medication.name,
              defaultDosage: row.medication.newDosage,
              enabled: true
            });
            await transaction.set(medBaseDoc, newMedBase.toJson());
            const medDoc = doc(medicationCollectionRef);
            await transaction.set(medDoc, {
              ...row.medication.toJson(),
              modelId: medDoc.id,
              medBaseId: medBaseDoc.id,
              carePlanId
            });
            const adherenceDoc = doc(adherenceCollectionRef);
            await transaction.set(adherenceDoc, {
              ...row.adherence.toJson(),
              modelId: adherenceDoc.id,
              medicationId: medDoc.id,
              medicationBaseId: medBaseDoc.id,
              carePlanId
            });
            if (row.note) {
              const noteDoc = doc(noteCollectionRef);
              await transaction.set(noteDoc, {
                ...row.note.toJson(),
                modelId: noteDoc.id,
                noteSourceId:
                  row.note.noteType === ClientNoteType.Medication
                    ? medDoc.id
                    : row.note.noteType === ClientNoteType.MedAdherence
                    ? adherenceDoc.id
                    : ""
              });
            }
          }
        }
      });
    } catch (error) {
      console.log("Failed to save care plan medications and adherence", error);
      throw error;
    }
  }

  static async saveTasks({
    clientId,
    carePlanId,
    tasks
  }: {
    clientId: string;
    carePlanId: string;
    tasks: ClientTask[];
  }) {
    if (!clientId || !carePlanId) {
      throw new Error("Missing required parameters");
    }
    try {
      await runTransaction(Database, async (transaction) => {
        const carePlanRef = doc(
          Database,
          `clients/${clientId}/carePlans/${carePlanId}`
        );
        const carePlanDoc = CarePlan.fromFirestore(await getDoc(carePlanRef));

        if (!carePlanDoc) {
          throw new Error("Could not fetch care plan");
        }

        // split up new Tasks from those that just need to be updated
        const taskUpdates = tasks.filter((t) => !!t.modelId);
        const newTasks = tasks.filter((t) => !t.modelId);

        if (taskUpdates.length > 0) {
          for (const tu of taskUpdates) {
            const taskUpdateRef = doc(
              Database,
              `clients/${clientId}/carePlans/${carePlanId}/clientTasks/${tu.modelId}`
            );
            // TODO: update task to new values -- recalculate expected completion count here
            await transaction.update(taskUpdateRef, tu.toJson());
          }
        }

        if (newTasks.length > 0) {
          for (const nt of newTasks) {
            const clientTaskCollectionRef = collection(
              Database,
              `clients/${clientId}/carePlans/${carePlanId}/clientTasks`
            );
            const clientTaskDocRef = doc(clientTaskCollectionRef);
            // TODO: confirm that completion count is correct with a new task
            await transaction.set(clientTaskDocRef, {
              ...nt.toJson(),
              modelId: clientTaskDocRef.id
            });
          }
        }
      });
    } catch (error) {
      console.log("Failed to save care plan tasks", error);
      throw error;
    }
  }

  static async saveFollowUpAndMonitoringData({
    clientId,
    carePlanId,
    tasks,
    appointment
  }: {
    clientId: string;
    carePlanId: string;
    tasks?: ClientTask[];
    appointment?: Appointment;
  }) {
    if (tasks?.length === 0 && !appointment) return;
    try {
      const carePlanRef = doc(
        Database,
        `clients/${clientId}/carePlans/${carePlanId}`
      );
      const carePlanDoc = CarePlan.fromFirestore(await getDoc(carePlanRef));

      if (!carePlanDoc) {
        throw new Error("Could not fetch care plan");
      }

      const taskUpdates = tasks?.filter((t) => !!t.modelId) ?? [];
      const newTasks = tasks?.filter((t) => !t.modelId) ?? [];
      await runTransaction(Database, async (transaction) => {
        if (taskUpdates.length > 0) {
          for (const tu of taskUpdates) {
            const taskUpdateRef = doc(
              Database,
              `clients/${clientId}/carePlans/${carePlanId}/clientTasks/${tu.modelId}`
            );
            // if there isn't a last bpGoal or if provided value is different, update careplan with new bpGoal
            await transaction.update(taskUpdateRef, tu.toJson());
          }
        }

        if (newTasks.length > 0) {
          for (const nt of newTasks) {
            const clientTaskCollectionRef = collection(
              Database,
              `clients/${clientId}/carePlans/${carePlanId}/clientTasks`
            );
            const clientTaskDocRef = doc(clientTaskCollectionRef);
            await transaction.set(clientTaskDocRef, {
              ...nt.toJson(),
              modelId: clientTaskDocRef.id
            });
          }
        }

        if (appointment) {
          const appointmentCollectionRef = collection(Database, "appointments");
          const apptDoc = doc(appointmentCollectionRef);
          await transaction.set(apptDoc, {
            ...appointment.toJson(),
            id: apptDoc.id
          });
        }
      });
    } catch (e) {
      console.log("Failed to save follow up and monitoring data");
      throw e;
    }
  }

  static async saveMedUpdates({
    clientId,
    carePlanId,
    currentMedications,
    unlistedMedications
  }: {
    clientId: string;
    carePlanId: string;
    currentMedications: { medication: Medication; note?: ClientNote }[];
    unlistedMedications: { medication: Medication; note?: ClientNote }[];
  }) {
    if (!clientId || !carePlanId) {
      throw new Error("Missing required parameters");
    }
    try {
      await runTransaction(Database, async (transaction) => {
        const carePlanRef = doc(
          Database,
          `clients/${clientId}/carePlans/${carePlanId}`
        );
        const carePlanDoc = CarePlan.fromFirestore(await getDoc(carePlanRef));

        if (!carePlanDoc) {
          throw new Error("Could not fetch care plan");
        }

        // create currentMedications list
        const medicationCollectionRef = collection(
          Database,
          `clients/${clientId}/carePlans/${carePlanId}/medications`
        );
        const noteCollectionRef = collection(
          Database,
          `clients/${clientId}/clientNotes`
        );

        const existingMedications = (
          await getDocs(medicationCollectionRef)
        ).docs.map((d) => Medication.fromFirestore(d));

        for (const row of currentMedications) {
          // compare provided medication against list of existingMedications to determine if this is an update
          const existingMed = existingMedications.find(
            (med) => med.modelId === row.medication.modelId
          );
          const dosageWasChanged =
            existingMed?.newDosage.strength !==
              row.medication.newDosage.strength ||
            existingMed?.newDosage.frequencyPeriod !==
              row.medication.newDosage.frequencyPeriod ||
            existingMed?.newDosage.frequencyCount !==
              row.medication.newDosage.frequencyCount ||
            existingMed?.newDosage.durationCount !==
              row.medication.newDosage.durationCount;

          if (!existingMed) {
            // if item isn't an update on an existing medication, add it as a new medication
            const medicationDoc = doc(medicationCollectionRef);
            await transaction.set(medicationDoc, {
              ...row.medication.toJson(),
              modelId: medicationDoc.id,
              carePlanId: carePlanId
            });
            if (row.note) {
              const noteDoc = doc(noteCollectionRef);
              await transaction.set(noteDoc, {
                ...row.note.toJson(),
                modelId: noteDoc.id,
                noteSourceId:
                  row.note.noteType === ClientNoteType.Medication
                    ? medicationDoc.id
                    : ""
              });
            }
          } else if (dosageWasChanged) {
            // this medication is an update on an existing one
            // update oldMedication cancelDate
            const existingMedDocRef = doc(
              Database,
              `clients/${clientId}/carePlans/${carePlanId}/medications/${existingMed.modelId}`
            );
            await transaction.update(existingMedDocRef, {
              rxCancelDate: new Date().toISOString()
            });

            // set oldDosage on new medication
            row.medication.oldDosage = existingMed.newDosage;
            // add new medication to collection
            const updatedMedDocRef = doc(medicationCollectionRef);
            await transaction.set(updatedMedDocRef, {
              ...row.medication.toJson(),
              modelId: updatedMedDocRef.id
            });

            if (row.note) {
              const noteDoc = doc(noteCollectionRef);
              await transaction.set(noteDoc, {
                ...row.note.toJson(),
                modelId: noteDoc.id,
                noteSourceId:
                  row.note.noteType === ClientNoteType.Medication
                    ? updatedMedDocRef.id
                    : ""
              });
            }
          } else if (row.note) {
            // this change just adds a note
            const noteDoc = doc(noteCollectionRef);
            await transaction.set(noteDoc, {
              ...row.note.toJson(),
              modelId: noteDoc.id,
              noteSourceId:
                row.note.noteType === ClientNoteType.Medication
                  ? existingMed.modelId
                  : ""
            });
          }
        }

        const medBaseRef = collection(Database, "medicationBases");
        const existingMedBases = (await getDocs(medBaseRef)).docs.map((mb) =>
          MedicationBase.fromFirestore(mb)
        );

        for (const row of unlistedMedications) {
          const isDuplicateMedBase = existingMedBases.some(
            (emb) =>
              emb.name === row.medication.name &&
              emb.placeBasedCareProvId === row.medication.placeBasedCareProvId
          );
          if (!isDuplicateMedBase) {
            // create new med base
            const medBaseDoc = doc(medBaseRef);
            const newMedBase = new MedicationBase({
              id: medBaseDoc.id,
              placeBasedCareProvId: row.medication.placeBasedCareProvId,
              name: row.medication.name,
              defaultDosage: row.medication.newDosage,
              enabled: true
            });
            await transaction.set(medBaseDoc, newMedBase.toJson());
            const medDoc = doc(medicationCollectionRef);
            await transaction.set(medDoc, {
              ...row.medication.toJson(),
              modelId: medDoc.id,
              medBaseId: medBaseDoc.id,
              carePlanId
            });
            if (row.note) {
              const noteDoc = doc(noteCollectionRef);
              await transaction.set(noteDoc, {
                ...row.note.toJson(),
                modelId: noteDoc.id,
                noteSourceId:
                  row.note.noteType === ClientNoteType.Medication
                    ? medDoc.id
                    : ""
              });
            }
          }
        }
      });
    } catch (error) {
      console.log("Failed to save care plan medication updates", error);
      throw error;
    }
  }

  static async activateCarePlan({
    clientId,
    carePlanId
  }: {
    clientId: string;
    carePlanId: string;
  }) {
    const carePlanRef = doc(
      Database,
      `clients/${clientId}/carePlans/${carePlanId}`
    );
    const carePlanDoc = CarePlan.fromFirestore(await getDoc(carePlanRef));

    if (!carePlanDoc) {
      throw new Error("Could not fetch care plan");
    }
    try {
      await runTransaction(Database, async (transaction) => {
        if (carePlanDoc.status === PlanStatus.Draft) {
          const clientDocRef = doc(Database, `clients/${clientId}`);
          await transaction.update(carePlanRef, {
            status: PlanStatus.Current
          });
          await transaction.update(clientDocRef, {
            currentCarePlanId: carePlanId
          });
        }
      });
    } catch (e) {
      console.log("Failed to activate care plan", e);
      throw e;
    }
  }
}
