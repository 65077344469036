import { ClientTask } from '@oben-core-web/models/client-task';
import { ClientTaskService } from '@oben-core-web/services/client-task-service';
import { useState, useEffect } from 'react';

const useClientTasks = ({
  clientId,
  carePlanId,
}: {
  clientId: string;
  carePlanId: string;
}) => {
  const [clientTasks, setClientTasks] = useState<ClientTask[]>([]);

  useEffect(() => {
    let mounted = true;
    const fetchClientTasks = async () => {
      if (!mounted || !clientId || !carePlanId) return;
      const clientTaskService = new ClientTaskService();
      const tasks = await clientTaskService.getClientTasks(
        clientId,
        carePlanId
      );
      setClientTasks(tasks);
    };
    fetchClientTasks();
    return () => {
      mounted = false;
    };
  }, [clientId, carePlanId]);

  return clientTasks;
};

export default useClientTasks;
