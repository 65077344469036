import { DocumentSnapshot } from '@firebase/firestore';
import { ASCVDRiskService } from '../services/ascvd-risk-service';
import { Sex, SexHelper, Race, RaceHelper, UserType, UserTypeHelper } from '../constants/core-enums';

export interface iASCVDRiskData {
  id: string;
  clientId: string;
  // pharmacistId: string; // TODO: do we need this?
  age: number; // in years (derived from birthdate)
  sex: Sex;
  race: Race; // derived from ethnicities
  totalCholesterol: number;
  HDLCholesterol: number;
  systolicBp: number;
  isTreatedForHypertension: boolean;
  isDiabetic: boolean;
  isSmoker: boolean;
  riskDate: Date;
  score: number | null; // as a percentage, calculated from the other fields
  isInvalid: boolean; // flag for whether reading is valid or not
  invalidDate: Date | null; // time that reading was flagged as invalid
  invalidatorType: UserType | null;
  invalidatorId: string | null; // userId of person that flagged it as invalid
}




export class ASCVDRisk {
  id: string;
  clientId: string;
  // pharmacistId: string; // TODO: do we need this?
  age: number; // in years (derived from birthdate)
  sex: Sex;
  race: Race; // derived from ethnicities
  totalCholesterol: number;
  HDLCholesterol: number;
  systolicBp: number;
  isTreatedForHypertension: boolean;
  isDiabetic: boolean;
  isSmoker: boolean;
  riskDate: Date;
  score: number | null; // as a percentage, calculated from the other fields
  isInvalid: boolean; // flag for whether reading is valid or not
  invalidDate: Date | null; // time that reading was flagged as invalid
  invalidatorType: UserType | null;
  invalidatorId: string | null; // userId of person that flagged it as invalid

  constructor({
    id,
    clientId,
    // pharmacistId, // TODO: do we need this?
    age,
    sex,
    race,
    totalCholesterol,
    HDLCholesterol,
    systolicBp,
    isTreatedForHypertension,
    isDiabetic,
    isSmoker,
    riskDate,
    score,
    isInvalid = false,
    invalidDate,
    invalidatorType,
    invalidatorId,
  }: iASCVDRiskData) {
    this.id = id;
    this.clientId = clientId;
    // this.pharmacistId = pharmacistId; // TODO: do we need this?
    this.age = age;
    this.sex = sex;
    this.race = race;
    this.totalCholesterol = totalCholesterol;
    this.HDLCholesterol = HDLCholesterol;
    this.systolicBp = systolicBp;
    this.isTreatedForHypertension = isTreatedForHypertension;
    this.isDiabetic = isDiabetic;
    this.isSmoker = isSmoker;
    this.riskDate = riskDate;
    this.score = score;
    this.isInvalid = isInvalid;
    this.invalidDate = invalidDate;
    this.invalidatorType = invalidatorType;
    this.invalidatorId = invalidatorId;
  }

  //
  // Misc helper methods
  //
  async updateDb() {
    const db = new ASCVDRiskService();
    await db.updateASCVDRisk(this);
  }

  // Factories
  //
  static fromFirestore(doc: DocumentSnapshot): ASCVDRisk {
    const data = doc.data() as { [key: string]: any };
    return ASCVDRisk.fromMap(doc.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): ASCVDRisk {
    return new ASCVDRisk({
      id: id,
      clientId: data["clientId"] || "",
      // pharmacistId: data["pharmacistId"] || "", // TODO: do we need this?
      age: data["age"] || 0,
      sex: SexHelper.valueOf(data["sex"]) || Sex.Unknown,
      race: RaceHelper.valueOf(data["race"]) || Race.Black,
      totalCholesterol: data["totalCholesterol"] || 0,
      HDLCholesterol: data["HDLCholesterol"] || 0,
      systolicBp: data["systolicBp"] || 0,
      isTreatedForHypertension: data["isTreatedForHypertension"] || false,
      isDiabetic: data["isDiabetic"] || false,
      isSmoker: data["isSmoker"] || false,
      riskDate: new Date(data["riskDate"]) || new Date(1, 0, 1),
      score: data["score"] || 0,
      isInvalid: data["isInvalid"] || false,
      invalidDate: data["invalidDate"] ? new Date(data["invalidDate"]) : null,
      invalidatorType: UserTypeHelper.valueOf(data["invalidatorType"] ?? "") ?? null,
      invalidatorId: data["invalidatorId"] ?? null,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      clientId: this.clientId,
      // pharmacistId: this.pharmacistId, // TODO: do we need this?
      age: this.age,
      sex: this.sex,
      race: this.race,
      totalCholesterol: this.totalCholesterol,
      HDLCholesterol: this.HDLCholesterol,
      systolicBp: this.systolicBp,
      isTreatedForHypertension: this.isTreatedForHypertension,
      isDiabetic: this.isDiabetic,
      isSmoker: this.isSmoker,
      riskDate: this.riskDate.toISOString(),
      score: this.score,
      isInvalid: this.isInvalid,
      invalidDate: this.invalidDate?.toISOString(),
      invalidatorType: this.invalidatorType,
      invalidatorId: this.invalidatorId,
    };
  }
}
