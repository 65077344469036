import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { BpReading } from '../models/bp-reading';
import { GaLog } from '../utils/ga-log';

export class BpReadingService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = 'bpReadings';
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get all BP readings for a specified client  
  // TODO: Implement optional filtering
  async getClientBpReadings(clientId: string): Promise<BpReading[]> {
    const queryRef = query(
      this._collectionReference,
      where('clientId', '==', clientId),
      orderBy('readingDate', 'desc')
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => BpReading.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getBpReading(bpReadingId: string): Promise<BpReading> {
    try {
      const docRef = doc(this._collectionReference, bpReadingId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`BP Reading not found: ${bpReadingId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return BpReading.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addBpReading(bpReading: BpReading): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, bpReading.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateBpReading(bpReading: BpReading): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, bpReading.id);
      await updateDoc(docRef, bpReading.toJson());
      GaLog.updateDocument(this._collectionReference.path, bpReading.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // BpReadings can only be updated, not deleted
  // async deleteBpReading(bpReadingId: string): Promise<void> {} 
}
