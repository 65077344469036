import { BpRange, BpRangeHelper } from '../constants/core-enums';

export interface IBpGoalData {
  systolic: number;
  diastolic: number;
  setDate: Date;
}

export class BpGoal {
  systolic: number;
  diastolic: number;
  setDate: Date;

  constructor({ 
    systolic, 
    diastolic, 
    setDate,
  }: IBpGoalData) {
    this.systolic = systolic;
    this.diastolic = diastolic;
    this.setDate = setDate;
  }

  get range(): BpRange {
    return BpRangeHelper.lookup(this.systolic, this.diastolic) ?? BpRange.Error;
  }

  static fromMap(data: { [key: string]: any }): BpGoal {
    return new BpGoal({
      systolic: data["systolic"] ?? 0,
      diastolic: data["diastolic"] ?? 0,
      setDate: data["setDate"] ? new Date(data["setDate"]) : new Date(1, 0, 1), // default to 1/1/0001
    });
  }

  toJson(): { [key: string]: any } {
    return {
      "systolic": this.systolic,
      "diastolic": this.diastolic,
      "setDate": this.setDate.toISOString(),
    };
  }
}
