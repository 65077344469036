import { BarberUser } from "@oben-core-web/models/barber-user";
import { BarberUserService } from "@oben-core-web/services/barber-user-service";
import { useState, useEffect } from "react";

const useBarber = (placeBasedCareProvId: string) => {
  const [barbers, setBarbers] = useState<BarberUser[]>();
  useEffect(() => {
    const fetchBarbers = async () => {
      const barberUserService = new BarberUserService();
      if (placeBasedCareProvId) {
        const barbers =
          await barberUserService.getBarbersByPlaceBasedCareProvId(
            placeBasedCareProvId
          );
        setBarbers(barbers);
      }
    };
    fetchBarbers();
  }, [placeBasedCareProvId]);
  return { barbers };
};

export default useBarber;
