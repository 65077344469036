import { useState, useEffect } from 'react';
import { ClientUserService } from '@oben-core-web/services/client-user-service';
import { ClientUser } from '@oben-core-web/models/client-user';

const usePatients = () => {
  const [clientUsers, setClientUsers] = useState<ClientUser[]>([]);
  useEffect(() => {
    let loading = true;
    const fetchClientUsers = async () => {
      if (loading) {
        const clientUserService = new ClientUserService();
        const clients = await clientUserService.getAllClientUsers();
        if (clients.length) {
          setClientUsers(clients);
        }
        loading = false;
      }
    };
    fetchClientUsers();
    return () => {
      loading = false;
    };
  }, []);
  return { clientUsers };
};

export default usePatients;
