import React, { useState } from "react";
import {
  Box,
  Text,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepSeparator,
  Center,
  Flex,
  HStack,
  Button,
  Card,
  StepIcon
} from "@chakra-ui/react";
import { ClientUser } from "@oben-core-web/models/client-user";
import useCurrentUser from "@/hooks/useCurrentUser";
import CPWizardStep1 from "../molecules/CPWizardStep1";
import CPWizardStep2 from "../molecules/CPWizardStep2";
import CPWizardStep3 from "../molecules/CPWizardStep3";
import CPWizardStep4 from "../molecules/CPWizardStep4";
import CPWizardStep5 from "../molecules/CPWizardStep5";
import CPWizardStep6 from "../molecules/CPWizardStep6";
import CPWizardStep7 from "../molecules/CPWizardStep7";
import CPWizardSummary from "../molecules/CPWizardSummary";

interface Props {
  patient: ClientUser;
  carePlanId: string;
  exitWizard: () => void;
}

// TODO: disable carePlan edits if currentUser is Care Manager
// TODO: add resolvers to validate each form
const CarePlanWizard: React.FC<Props> = ({
  patient,
  carePlanId,
  exitWizard
}) => {
  const { webUser: currentUser } = useCurrentUser();
  const [currentScreen, setCurrentScreen] = useState(0);
  const [currentWindowStep, setCurrentWindowStep] = useState(0);

  const handleNext = () => {
    if (currentWindowStep < windowLengths[currentScreen] - 1) {
      setCurrentWindowStep(currentWindowStep + 1);
    } else {
      setCurrentScreen(currentScreen + 1);
      setCurrentWindowStep(0);
    }
  };

  const handlePrev = () => {
    if (currentWindowStep > 0) {
      setCurrentWindowStep(currentWindowStep - 1);
    } else if (currentScreen > 0) {
      const prevScreenIndex = currentScreen - 1;
      const prevWindowSize = windowLengths[prevScreenIndex] - 1;
      setCurrentWindowStep(prevWindowSize);
      setCurrentScreen(currentScreen - 1);
    }
  };

  // TODO: show summary on first page if in edit mode -- include 'edit' to enter wizard if in edit mode

  const steps = [
    {
      label: "Step 1",
      subSteps: [
        {
          title: "Understanding Your Blood Pressure",
          subtitle: "Blood Pressure Goal and Risk Factors",
          component: (
            <Box key={"step 1-1"}>
              <CPWizardStep1
                carePlanId={carePlanId}
                clientId={patient.uid}
                onSubmit={handleNext}
                onCancel={exitWizard}
              />
            </Box>
          )
        },
        {
          title: "Understanding Your Blood Pressure",
          subtitle: "Current Medications",
          component: (
            <Box key='step1-2' w={"full"} h={"full"}>
              {currentUser ? (
                // Current medications
                <CPWizardStep2
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                  pharmacistId={currentUser.uid}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        }
      ]
    },
    {
      label: "Step 2",
      subSteps: [
        {
          title: "Your Care Plan",
          subtitle: "",
          component: (
            <Flex
              key='step2-1'
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              h={"full"}
              w={"full"}
              minH={"35rem"}
            >
              <Text fontSize='xx-large' as='h1' mt={"15%"} maxW={"50%"}>
                Now we will talk through creating a plan and setting some smart
                goals
              </Text>
              <HStack w={"full"} justifyContent={"space-between"}>
                <Button onClick={handlePrev}>Previous</Button>
                <Button onClick={handleNext}>Next</Button>
              </HStack>
            </Flex>
          )
        },
        {
          title: "Understanding Your Care",
          subtitle: "Smart Goals",
          component: (
            <Box key='step2-2' w={"full"}>
              {currentUser ? (
                // Smart goals
                <CPWizardStep3
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                  pharmacistId={currentUser.uid}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        }
      ]
    },
    {
      label: "Step 3",
      subSteps: [
        {
          title: "Your Care Plan",
          subtitle: "",
          component: (
            <Flex
              key='step3-1'
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              h={"full"}
              w={"full"}
              minH={"35rem"}
            >
              <Text fontSize='xx-large' as='h1' mt={"15%"} maxW={"50%"}>
                Now we will talk through creating a plan and setting some health
                maintenance goals
              </Text>
              <HStack w={"full"} justifyContent={"space-between"}>
                <Button onClick={handlePrev}>Previous</Button>
                <Button onClick={handleNext}>Next</Button>
              </HStack>
            </Flex>
          )
        },
        {
          title: "Understanding Your Care",
          subtitle: "Health guidelines",
          component: (
            <Box key='step3-2' w={"full"}>
              {currentUser ? (
                // Health Maintenance Goals
                <CPWizardStep4
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                  pharmacistId={currentUser.uid}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        },
        {
          title: "Understanding Your Care",
          subtitle: "Cholesterol Readings",
          component: (
            <Box key='step4-2' w={"full"}>
              {currentUser ? (
                // Medication adjustments
                <CPWizardStep5
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        }
      ]
    },
    {
      label: "Step 4",
      subSteps: [
        {
          title: "Your Care Plan",
          subtitle: "",
          component: (
            <Flex
              key='step4-1'
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              h={"full"}
              w={"full"}
              minH={"35rem"}
            >
              <Text fontSize='xx-large' as='h1' mt={"15%"} maxW={"50%"}>
                Now we will talk through changes to your medication
              </Text>
              <HStack w={"full"} justifyContent={"space-between"}>
                <Button onClick={handlePrev}>Previous</Button>
                <Button onClick={handleNext}>Next</Button>
              </HStack>
            </Flex>
          )
        },
        {
          title: "Understanding Your Care",
          subtitle: "Changes To Your Medication",
          component: (
            <Box key='step4-2' w={"full"}>
              {currentUser ? (
                // Medication adjustments
                <CPWizardStep6
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                  pharmacistId={currentUser.uid}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        }
      ]
    },
    {
      label: "Step 5",
      subSteps: [
        {
          title: "Your Care Plan",
          component: (
            <Flex
              key='step4-1'
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              h={"full"}
              w={"full"}
              minH={"35rem"}
            >
              <Text fontSize='xx-large' as='h1' mt={"15%"} maxW={"50%"}>
                Now we will create a plan for monitoring and follow up
              </Text>
              <HStack w={"full"} justifyContent={"space-between"}>
                <Button onClick={handlePrev}>Previous</Button>
                <Button onClick={handleNext}>Next</Button>
              </HStack>
            </Flex>
          )
        },
        {
          title: "Follow up and Monitoring",
          subtitle: "Getting To Know Your Care Team",
          component: (
            <Box key='step5-2' w={"full"}>
              {currentUser ? (
                // Monitoring and Follow Up
                <CPWizardStep7
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                  pharmacistId={currentUser.uid}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        }
      ]
    },
    {
      label: "Step 6",
      subSteps: [
        {
          title: "Summary",
          subtitle: "",
          component: (
            <Center>
              <CPWizardSummary
                carePlanId={carePlanId}
                clientId={patient.uid}
                onSubmit={exitWizard}
                onCancel={handlePrev}
              />
            </Center>
          )
        }
      ]
    }
  ];

  const windowLengths = steps.map((s) => s.subSteps.length);

  return (
    <Card
      p={8}
      w='100%'
      h='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      bg={"white"}
      borderRadius={"lg"}
    >
      {steps[currentScreen].subSteps[currentWindowStep].title && (
        <Text fontSize='xx-large' h={"3rem"}>
          {steps[currentScreen].subSteps[currentWindowStep].title}
        </Text>
      )}
      {steps[currentScreen].subSteps[currentWindowStep].subtitle && (
        <Text fontSize='large' h={"3rem"}>
          {steps[currentScreen].subSteps[currentWindowStep].subtitle}
        </Text>
      )}
      <Box w='100%'>
        <Stepper
          size='lg'
          index={currentScreen}
          w='100%'
          colorScheme='teal'
          mb={8}
          mt={4}
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  active={index === currentScreen}
                  complete={<StepIcon />}
                />
              </StepIndicator>
              <StepTitle>{step.label}</StepTitle>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
        <Box w='100%' p={4}>
          <Box minH={"30rem"} w={"100%"}>
            {steps[currentScreen].subSteps[currentWindowStep].component}
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CarePlanWizard;
