import { UserType, UserTypeHelper } from '../constants/core-enums';

export enum AppointmentStatus {
  Unknown = 'Unknown',
  New = 'New',
  Confirmed = 'Confirmed',
  Canceled = 'Canceled',
  LateCanceled = 'LateCanceled',
  NoShow = 'NoShow',
  NotCompleted = 'NotCompleted',
  Completed = 'Completed',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class AppointmentStatusHelper {
  static valueOf(searchString: string | null): AppointmentStatus | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(AppointmentStatus).includes(searchString as AppointmentStatus)) //
      ? searchString as AppointmentStatus
      : null;
  }
}

export interface IAppointmentStatusChangeData {
  status: AppointmentStatus;
  date: Date;
  editorType: UserType;
  editorId: string;
  details: string;
}

// AppointmentStatusChange records are maintained in a list on Appointment
export class AppointmentStatusChange {
  status: AppointmentStatus;
  date: Date;
  editorType: UserType;
  editorId: string;
  details: string;

  constructor({ 
    status, 
    date, 
    editorType, 
    editorId, 
    details,
  }: IAppointmentStatusChangeData) {
    this.status = status;
    this.date = date;
    this.editorType = editorType;
    this.editorId = editorId;
    this.details = details;
  }

  static fromMap(data: { [key: string]: any }): AppointmentStatusChange {
    return new AppointmentStatusChange({
      status: AppointmentStatusHelper.valueOf(data["status"] ?? "") ?? AppointmentStatus.Unknown,
      date: data["date"] ? new Date(data["date"]) : new Date(1, 0, 1), // default to 1/1/0001
      editorType: UserTypeHelper.valueOf(data["editorType"] ?? "") ?? UserType.Unknown,
      editorId: data["editorId"] ?? "",
      details: data["details"] ?? "",
    });
  }

  toJson(): { [key: string]: any } {
    return {
      "status": this.status,
      "date": this.date.toISOString(),
      "editorType": this.editorType,
      "editorId": this.editorId,
      "details": this.details,
    };
  }
}