import { CholesterolReading } from "@oben-core-web/models/cholesterol-reading";
import { CholesterolReadingService } from "@oben-core-web/services/cholesterol-reading-service";
import { useEffect, useState } from "react";

const useCholesterolReadings = (clientId: string) => {
  const [cholesterolReadings, setCholesterolReadings] = useState<
    CholesterolReading[]
  >([]);

  useEffect(() => {
    const fetchReadings = async () => {
      if (clientId) {
        const cholesterolReadingService = new CholesterolReadingService();
        const readings =
          await cholesterolReadingService.getClientCholesterolReadings(
            clientId
          );
        setCholesterolReadings(readings);
      }
    };
    fetchReadings();
  }, [clientId]);

  return { cholesterolReadings };
};

export default useCholesterolReadings;
