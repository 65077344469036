export interface IDefaultWorkingHoursData {
  dayOfWeek: number; // Mon=1, Tue=2, ..., Sun=7
  startTime: Date;
  endTime: Date;
}

export class DefaultWorkingHours {
  dayOfWeek: number; // Mon=1, Tue=2, ..., Sun=7
  startTime: Date;
  endTime: Date;

  constructor({ dayOfWeek, startTime, endTime }: IDefaultWorkingHoursData) {
    this.dayOfWeek = dayOfWeek;
    this.startTime = startTime;
    this.endTime = endTime;
  }

  // Static method for creating an instance from a map
  static fromMap(data: { [key: string]: any }): DefaultWorkingHours {
    return new DefaultWorkingHours({
      dayOfWeek: data["dayOfWeek"],
      startTime: new Date(data["startTime"]),
      endTime: new Date(data["endTime"]),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      "dayOfWeek": this.dayOfWeek,
      "startTime": this.startTime.toISOString(),
      "endTime": this.endTime.toISOString(),
    };
  }
}
