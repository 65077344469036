import { DateTime } from "luxon";
import { FreqPeriod } from "@oben-core-web/constants/core-enums";

export const calculateExpectedCount = (
  start: Date,
  end: Date,
  count: number,
  frequency: FreqPeriod
) => {
  const startDate = DateTime.fromJSDate(start);
  const endDate = DateTime.fromJSDate(end);
  const period =
    frequency === FreqPeriod.Weekly
      ? "weeks"
      : frequency === FreqPeriod.Daily
      ? "days"
      : "invalid";

  if (period === "invalid") return 0;

  const diff = endDate.diff(startDate, period).toObject();

  return Math.floor(diff[period]! * count);
};
