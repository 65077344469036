import {
  collectionGroup,
  collection,
  DocumentData,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  Query,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { CustomWorkingHours } from '../models/custom-working-hours';
import { GaLog } from '../utils/ga-log';

export class CustomWorkingHoursService {
  private _parentCollectionName: string;
  private _collectionName: string;
  private _collectionGroup: Query<DocumentData, DocumentData>;

  constructor() {
    this._parentCollectionName = 'webUsers';
    this._collectionName = 'customWorkingHours';
    this._collectionGroup = collectionGroup(Database, this._collectionName);
  }

  // get all working hours slots for a specified staff (web) user
  async getStaffCustomWorkingHours({
    userId,
    start,
    end,
  }: {
    userId: string;
    start: Date;
    end: Date;
  }): Promise<CustomWorkingHours[]> {
    const path = `${this._parentCollectionName}/${userId}/${this._collectionName}`;
    try {
      if (!userId || !start || !end) {
        return [];
      }
      const hoursQuery = query(
        collection(Database, path),
        where('start', '>=', start.toISOString()),
        where('end', '<=', end.toISOString()),
      );
      const qSnapshot = await getDocs(hoursQuery);
      GaLog.readCollection(path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => CustomWorkingHours.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(path, error);
      throw error;
    }
  }

  // get a single working slot
  async getCustomWorkingSlot(slotId: string): Promise<CustomWorkingHours> {
    try {
      const slotQuery = query(
        this._collectionGroup,
        where('modelId', '==', slotId),
      );
      const qSnapshot = await getDocs(slotQuery);
      if (qSnapshot.empty) {
        throw new Error(`CustomWorkingHours not found: ${slotId}`);
      }
      const slot = CustomWorkingHours.fromFirestore(qSnapshot.docs[0]);
      GaLog.readDocument(`${this._parentCollectionName}/${slot.userId}/${this._collectionName}`, slot.modelId);
      return slot;
    } catch (error) {
      GaLog.readError(`${this._parentCollectionName}/?????/${this._collectionName}`, error);
      throw error;
    }
  }

  async addCustomWorkingSlot(slot: CustomWorkingHours): Promise<string> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${slot.userId}/${this._collectionName}`
    );
    try {
      const docRef = await addDoc(subcollectionRef, slot.toJson());
      GaLog.addDocument(subcollectionRef.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(subcollectionRef.path, error);
      throw error;
    }
  }

  async updateCustomWorkingSlot(slot: CustomWorkingHours): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${slot.userId}/${this._collectionName}`,
    );
    try {
      const docRef = doc(subcollectionRef, slot.modelId);
      await updateDoc(docRef, slot.toJson());
      GaLog.updateDocument(subcollectionRef.path, docRef.id);
      return;
    } catch (error) {
      GaLog.updateError(subcollectionRef.path, error);
      throw error;
    }
  }

  async deleteCustomWorkingSlot(slot: CustomWorkingHours): Promise<void> {
    const subcollectionRef = collection(
      Database, 
      `/${this._parentCollectionName}/${slot.userId}/${this._collectionName}/`,
    );
    try {
      const docRef = doc(subcollectionRef, slot.modelId); 
      await deleteDoc(docRef);
      GaLog.deleteDocument(subcollectionRef.path, slot.modelId);
      return;
    } catch (error) {
      GaLog.deleteError(subcollectionRef.path, error);
      throw error;
    }
  }
}
