import React from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Heading,
  Input,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  HStack,
  IconButton,
  useToast
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { convertToUTC } from "@/lib/timezone"; // Adjust the import path as needed
import { WebUser } from "@oben-core-web/models/web-user"; // Adjust the import path as needed
import { DefaultWorkingHours } from "@oben-core-web/models/default-working-hours"; // Adjust the import path as needed
import { WebUserService } from "@oben-core-web/services/web-user-service"; // Adjust the import path as needed
// import { toast } from 'react-toastify';
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const userProfileSchema = yup.object().shape({
  name: yup.object().shape({
    first: yup.string().required("First name is required"),
    last: yup.string().required("Last name is required"),
    display: yup.string().required("Display name is required")
  }),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: yup.string().required("Phone number is required"),
  defaultWorkingHours: yup.array().of(
    yup.object().shape({
      dayOfWeek: yup.number().required("Day is required"),
      startTime: yup.string().required("Start time is required"),
      endTime: yup.string().required("End time is required")
    })
  )
});

const daysOfWeek = [
  { day: 0, name: "Sunday" },
  { day: 1, name: "Monday" },
  { day: 2, name: "Tuesday" },
  { day: 3, name: "Wednesday" },
  { day: 4, name: "Thursday" },
  { day: 5, name: "Friday" },
  { day: 6, name: "Saturday" }
];

const UserProfileForm = ({
  user,
  onToggleForm
}: {
  user: WebUser;
  onToggleForm: () => void;
}) => {
  const toast = useToast();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: (user?.name as {
        first: string;
        last: string;
        display: string;
      }) ?? { first: "", last: "", display: "" },
      email: user?.email ?? "",
      phoneNumber: user?.phoneNumber ?? "",
      defaultWorkingHours:
        user?.defaultWorkingHours.map((dwh) => ({
          ...dwh,
          startTime: DateTime.now()
            .set({
              hour: dwh.startTime.getHours(),
              minute: dwh.startTime.getMinutes()
            })
            .toFormat("HH':'mm"),
          endTime: DateTime.now()
            .set({
              hour: dwh.endTime.getHours(),
              minute: dwh.endTime.getMinutes()
            })
            .toFormat("HH':'mm")
        })) ?? []
    },
    resolver: yupResolver(userProfileSchema)
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "defaultWorkingHours"
  });

  const onSubmit = async (values: any) => {
    try {
      const webUserService = new WebUserService();
      const webUser = new WebUser({
        ...user,
        ...values,
        defaultWorkingHours: values.defaultWorkingHours.map((dwh: any) => {
          const [startHour, startMin] = dwh.startTime.split(":");
          const [endHour, endMin] = dwh.endTime.split(":");
          return new DefaultWorkingHours({
            ...dwh,
            startTime: convertToUTC(
              new Date(1900, 0, 1, parseInt(startHour), parseInt(startMin))
            ),
            endTime: convertToUTC(
              new Date(1900, 0, 1, parseInt(endHour), parseInt(endMin))
            )
          });
        })
      });

      if (user?.uid) {
        await webUserService.updateWebUser(webUser);
        toast({ description: "Profile updated!", variant: "Success" });
        onToggleForm();
      } else {
        throw new Error("User missing in props");
      }
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <Box
      minWidth='75%'
      p={4}
      borderWidth='1px'
      borderRadius='lg'
      boxShadow='md'
    >
      <Heading as='h2' size='lg' mb={4}>
        Edit Profile
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4} align='stretch'>
          <FormControl isInvalid={!!errors.name?.first}>
            <FormLabel htmlFor='name.first'>First Name</FormLabel>
            <Controller
              name='name.first'
              control={control}
              render={({ field }) => <Input {...field} />}
            />
            <FormErrorMessage>{errors.name?.first?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.name?.last}>
            <FormLabel htmlFor='name.last'>Last Name</FormLabel>
            <Controller
              name='name.last'
              control={control}
              render={({ field }) => <Input {...field} />}
            />
            <FormErrorMessage>{errors.name?.last?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.name?.display}>
            <FormLabel htmlFor='name.display'>Display Name</FormLabel>
            <Controller
              name='name.display'
              control={control}
              render={({ field }) => <Input {...field} />}
            />
            <FormErrorMessage>{errors.name?.display?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel htmlFor='email'>Email</FormLabel>
            <Controller
              name='email'
              control={control}
              render={({ field }) => <Input {...field} />}
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.phoneNumber}>
            <FormLabel htmlFor='phoneNumber'>Phone Number</FormLabel>
            <Controller
              name='phoneNumber'
              control={control}
              render={({ field }) => <Input {...field} />}
            />
            <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
          </FormControl>
        </VStack>
        <Box mt={6}>
          <Heading as='h3' size='md' mb={4}>
            Working Hours
          </Heading>
          {fields.map((field, index) => (
            <HStack key={field.id} spacing={4} mb={2}>
              <FormControl
                isInvalid={!!errors.defaultWorkingHours?.[index]?.dayOfWeek}
              >
                <FormLabel htmlFor={`defaultWorkingHours.${index}.dayOfWeek`}>
                  Day
                </FormLabel>
                <Controller
                  name={`defaultWorkingHours.${index}.dayOfWeek`}
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      {daysOfWeek.map((day) => (
                        <option key={day.day} value={day.day}>
                          {day.name}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                <FormErrorMessage>
                  {errors.defaultWorkingHours?.[index]?.dayOfWeek?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!!errors.defaultWorkingHours?.[index]?.startTime}
              >
                <FormLabel htmlFor={`defaultWorkingHours.${index}.startTime`}>
                  Start Time
                </FormLabel>
                <Controller
                  name={`defaultWorkingHours.${index}.startTime`}
                  control={control}
                  render={({ field }) => <Input {...field} type='time' />}
                />
                <FormErrorMessage>
                  {errors.defaultWorkingHours?.[index]?.startTime?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!!errors.defaultWorkingHours?.[index]?.endTime}
              >
                <FormLabel htmlFor={`defaultWorkingHours.${index}.endTime`}>
                  End Time
                </FormLabel>
                <Controller
                  name={`defaultWorkingHours.${index}.endTime`}
                  control={control}
                  render={({ field }) => <Input {...field} type='time' />}
                />
                <FormErrorMessage>
                  {errors.defaultWorkingHours?.[index]?.endTime?.message}
                </FormErrorMessage>
              </FormControl>
              <IconButton
                aria-label='Remove working hour'
                icon={<CloseIcon />}
                onClick={() => remove(index)}
              />
            </HStack>
          ))}
          <Button
            leftIcon={<AddIcon />}
            onClick={() => append({ dayOfWeek: 0, startTime: "", endTime: "" })}
          >
            Add Working Hour
          </Button>
        </Box>
        <HStack mt={6} spacing={4}>
          <Button onClick={onToggleForm}>Cancel</Button>
          <Button type='submit' colorScheme='blue'>
            Submit
          </Button>
        </HStack>
      </form>
    </Box>
  );
};

export default UserProfileForm;
