import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { CholesterolReading } from '../models/cholesterol-reading';
import { GaLog } from '../utils/ga-log';

export class CholesterolReadingService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = 'cholesterolReadings';
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get all BP readings for a specified client  
  // TODO: Implement optional filtering
  async getClientCholesterolReadings(clientId: string): Promise<CholesterolReading[]> {
    const queryRef = query(
      this._collectionReference,
      where('clientId', '==', clientId),
      orderBy('readingDate', 'desc')
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => CholesterolReading.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getCholesterolReading(cholesterolReadingId: string): Promise<CholesterolReading> {
    try {
      const docRef = doc(this._collectionReference, cholesterolReadingId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`BP Reading not found: ${cholesterolReadingId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return CholesterolReading.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addCholesterolReading(cholesterolReading: CholesterolReading): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, cholesterolReading.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateCholesterolReading(cholesterolReading: CholesterolReading): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, cholesterolReading.id);
      await updateDoc(docRef, cholesterolReading.toJson());
      GaLog.updateDocument(this._collectionReference.path, cholesterolReading.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // CholesterolReadings can only be updated, not deleted
  // async deleteCholesterolReading(cholesterolReadingId: string): Promise<void> {} 
}
