// import useCarePlan from '@/hooks/useCarePlan';
import useClientCarePlans from "@/hooks/useClientCarePlans";
import useClientUser from "@/hooks/useClientUser";
import { Box, useDisclosure } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import CarePlanDetails from "../organisms/CarePlanDetails";

const CarePlan = () => {
  const { id, carePlanId } = useParams();
  const { clientUser } = useClientUser(id);
  const { carePlans } = useClientCarePlans(id);
  const { isOpen, onToggle } = useDisclosure();
  const carePlan = carePlans.find((cp) => cp.modelId === carePlanId);
  return (
    <Box p={4}>
      {!carePlan || !clientUser ? (
        <div>Loading...</div>
      ) : (
        <>
          {isOpen ? (
            <Box>Edit care plan</Box>
          ) : (
            <CarePlanDetails
              clientId={id!}
              carePlanId={carePlanId!}
              toggleEditModal={onToggle}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default CarePlan;
