export interface IUserNameData {
  display: string | null;
  first: string | null;
  last: string | null;
}

export class UserName {
  display: string | null;
  first: string | null;
  last: string | null;

  constructor({
    display, 
    first, 
    last,
  }: IUserNameData) {  
    this.display = display;
    this.first = first;
    this.last = last;
  }

  get fullName(): string {
    if (this.first && this.last) {
      return `${this.first} ${this.last}`;
    } else if (this.first) {
      return this.first;
    } else if (this.last) {
      return this.last;
    } else if (this.display) {
      return this.display;
    } else {
      return '';
    }
  }

  static fromMap(data: { [key: string]: any }): UserName {
    return new UserName({
      display: data["display"] ?? null,
      first: data["first"] ?? null,
      last: data["last"] ?? null,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      "display": this.display,
      "first": this.first,
      "last": this.last,
    };
  }
}