import useClientCarePlans from "@/hooks/useClientCarePlans";
import useClientUser from "@/hooks/useClientUser";
import {
  VStack,
  HStack,
  Button,
  Input,
  FormControl,
  FormLabel,
  Flex,
  Textarea
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";
import useCholesterolReadings from "@/hooks/useCholesterolReadings";
import { CholesterolReading } from "@oben-core-web/models/cholesterol-reading";
import useCurrentUser from "@/hooks/useCurrentUser";
import { CholesterolReadingService } from "@oben-core-web/services/cholesterol-reading-service";

interface ICPWizardStep5 {
  carePlanId: string;
  clientId: string;
  onSubmit?: () => void;
  onCancel?: () => void;
}

interface ICPWizardStep5Form {
  hdl: number;
  ldl: number;
  total: number;
  details?: string;
}

const CPWizardStep5 = ({
  carePlanId,
  clientId,
  onSubmit,
  onCancel
}: ICPWizardStep5) => {
  const { webUser } = useCurrentUser();
  const { clientUser } = useClientUser(clientId);
  const { carePlans } = useClientCarePlans(clientId);
  const carePlan = carePlans.find((cp) => cp.modelId === carePlanId);
  const { cholesterolReadings } = useCholesterolReadings(clientId);
  const lastReading = cholesterolReadings[0];
  const lastReadingRef = useRef(lastReading);
  const { register, handleSubmit, formState, reset } =
    useForm<ICPWizardStep5Form>({
      defaultValues: {
        hdl: lastReading?.HDL ?? 0,
        ldl: lastReading?.LDL ?? 0,
        total: lastReading?.total ?? 0,
        details: lastReading?.details ?? ""
      }
    });
  useEffect(() => {
    if (!isEqual(lastReading, lastReadingRef.current)) {
      lastReadingRef.current = lastReading;
      lastReading &&
        reset({
          hdl: lastReading.HDL,
          ldl: lastReading.LDL,
          total: lastReading.total,
          details: lastReading.details
        });
    }
  }, [reset, lastReading]);
  console.log({ formState });
  const submissionHandler = async (data: ICPWizardStep5Form) => {
    console.log("SUBMIT CALLED");
    if (!carePlan) {
      return;
    }
    try {
      const { ldl, hdl, total, details } = data;
      const { dirtyFields } = formState;
      if (Object.values(dirtyFields).length > 0) {
        const cholesterolReadingService = new CholesterolReadingService();
        const reading = new CholesterolReading({
          id: "",
          clientId,
          HDL: hdl,
          LDL: ldl,
          total: total,
          readingDate: new Date(),
          recorderType: webUser!.userType,
          recorderId: webUser!.uid,
          details: details ?? "",
          isInvalid: false,
          invalidDate: null,
          invalidatorType: null,
          invalidatorId: null
        });

        await cholesterolReadingService
          .addCholesterolReading(reading)
          .then(() => {
            onSubmit && onSubmit();
          });
      } else {
        onSubmit && onSubmit();
      }
    } catch (e) {
      console.log({ error: e });
    }
  };
  return (
    <>
      {clientUser && carePlan && (
        <form
          onSubmit={handleSubmit(submissionHandler, (errors) => {
            console.log({ errors });
          })}
          style={{ height: "26rem" }}
        >
          <VStack
            spacing={4}
            key='step5-1'
            alignItems={"flex-start"}
            w={"100%"}
            h={"full"}
          >
            <Flex
              // direction={"column"}
              justifyContent={"space-evenly"}
              w={"full"}
            >
              <FormControl display={"flex"} dir={"row"} alignItems={"center"}>
                <FormLabel fontSize={"sm"} w={"5rem"}>
                  HDL
                </FormLabel>
                <Input
                  size={"md"}
                  type='number'
                  w={"5rem"}
                  {...register("hdl")}
                />
              </FormControl>
              <FormControl display={"flex"} dir={"row"} alignItems={"center"}>
                <FormLabel fontSize={"sm"} w={"5rem"}>
                  LDL
                </FormLabel>
                <Input
                  size={"md"}
                  type='number'
                  w={"5rem"}
                  {...register("ldl")}
                />
              </FormControl>
              <FormControl display={"flex"} dir={"row"} alignItems={"center"}>
                <FormLabel fontSize={"sm"} w={"5rem"}>
                  Total
                </FormLabel>
                <Input
                  size={"md"}
                  type='number'
                  w={"5rem"}
                  {...register("total")}
                />
              </FormControl>
            </Flex>
            <FormControl display={"flex"} dir={"row"} alignItems={"center"}>
              <FormLabel fontSize={"sm"} w={"5rem"}>
                Details
              </FormLabel>
              <Textarea size={"md"} {...register("details")} />
            </FormControl>
          </VStack>
          <HStack mt={"2rem"} justifyContent={"space-between"}>
            {onCancel && <Button onClick={onCancel}>Previous</Button>}
            {onSubmit && <Button type='submit'>Next</Button>}
          </HStack>
        </form>
      )}
    </>
  );
};

export default CPWizardStep5;
