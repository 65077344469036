import useClientTasks from "@/hooks/useTasks";
import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  CloseButton,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { ClientTask, ClientTaskType } from "@oben-core-web/models/client-task";
import _ from "lodash";
import { DateTime } from "luxon";
import BPChart from "../molecules/BPChart";
import MedicationChart from "../molecules/MedicationChart";
import MedAdherenceChart from "../molecules/MedAdherenceChart";
import {
  TbCircleCheck,
  TbCircleX,
  TbEdit,
  TbEditCircle,
  TbParkingCircle
} from "react-icons/tb";

import { FreqPeriod } from "@oben-core-web/constants/core-enums";
import { ClientTaskService } from "@oben-core-web/services/client-task-service";
import {
  TaskStatus,
  TaskStatusChange
} from "@oben-core-web/models/task-status-change";
import { useForm } from "react-hook-form";
import { calculateExpectedCount } from "@/lib/clientTasks";
import { useState } from "react";

interface ICarePlanDetails {
  clientId: string;
  carePlanId: string;
  toggleEditModal: () => void;
}

const CarePlanDetails = ({
  clientId,
  carePlanId
}: // toggleEditModal
ICarePlanDetails) => {
  const [showAdherenceChart, setShowAdherenceChart] = useState(false);
  const clientTasks = useClientTasks({ clientId, carePlanId });
  const groupedTasks = _.groupBy(clientTasks, (task) => {
    return task.taskType;
  });
  const healthMaintenanceTasks = _.pick(groupedTasks, [
    ClientTaskType.HealthcareAction,
    ClientTaskType.HomeEducation
  ]);
  const healthMaintenanceTasksByCategory = _.groupBy(
    Object.values(healthMaintenanceTasks).flat(),
    (t) => t.taskCategory
  );
  const smartGoalTasksByCategory = _.groupBy(
    groupedTasks[ClientTaskType.SmartGoal],
    (task) => {
      const category = task.taskCategory;
      return category;
    }
  );
  const followUpTasks = _.pick(groupedTasks, [
    ClientTaskType.HealthcareAction,
    ClientTaskType.HomeBPMonitoring
  ]);
  return (
    <Flex direction='column'>
      <BPChart patientId={clientId} height={250} width={"100%"} />
      {showAdherenceChart ? (
        <MedAdherenceChart
          patientId={clientId}
          toggleChart={() => setShowAdherenceChart((c) => !c)}
          height={250}
        />
      ) : (
        <MedicationChart
          patientId={clientId}
          toggleChart={() => setShowAdherenceChart((c) => !c)}
          height={250}
        />
      )}
      {healthMaintenanceTasks && (
        <Flex direction='column'>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Text fontSize='x-large'>Your Health Maintenance Tasks</Text>
            {/* <Button onClick={toggleEditModal}>Edit</Button> */}
          </Flex>
          {_.toPairs(healthMaintenanceTasksByCategory).map(([type, tasks]) => {
            return (
              <Card
                direction='column'
                key={`hm-task-category-${type}`}
                rounded={"md"}
                p={2}
                mb={2}
              >
                <Text fontSize='large' as='b'>
                  {type === "Unknown" ? "General" : type}
                </Text>
                {tasks.map((task) => {
                  const latestStatus = getLastTaskStatus(task);
                  return (
                    <Flex
                      key={`care-plan-hm-task-${task.modelId}`}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{
                        fontWeight: "normal",
                        _hover: { fontWeight: "bold" }
                      }}
                    >
                      <Text>{task.name}</Text>
                      <Box>
                        {new Date() < task.dueDate! &&
                        task.expectedCount > task.taskCompletionDates.length
                          ? TaskStatusBadges.Overdue
                          : TaskStatusBadges[latestStatus?.status ?? "New"]}
                        <TaskModal task={task} />
                      </Box>
                    </Flex>
                  );
                })}
              </Card>
            );
          })}
          <Divider my={2} />
        </Flex>
      )}
      {smartGoalTasksByCategory && (
        <Flex direction='column'>
          <Text fontSize='x-large'>Your Smart Goals</Text>
          {_.toPairs(smartGoalTasksByCategory).map(([type, tasks]) => (
            <Card
              direction='column'
              key={`sg-task-category-${type}`}
              mb={2}
              p={2}
            >
              <Text fontSize='large' as='b'>
                {type}
              </Text>
              {tasks.map((task) => (
                <Flex
                  key={`care-plan-sg-task-${task.modelId}`}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{
                    fontWeight: "normal",
                    _hover: { fontWeight: "bold" }
                  }}
                >
                  <Text>{task.name}</Text>
                  <Flex>
                    <Text>
                      {Math.round(
                        (task.taskCompletionDates.length / task.expectedCount) *
                          100
                      )}
                      % Complete
                    </Text>
                    <TaskModal task={task} />
                  </Flex>
                </Flex>
              ))}
            </Card>
          ))}
          <Divider my={2} />
        </Flex>
      )}
      {Object.values(followUpTasks).length > 0 && (
        <>
          <Text fontSize='x-large'>Your Follow Up Tasks</Text>
          <Card direction='column' mb={2} p={2}>
            {Object.values(followUpTasks).map((tasks) =>
              tasks.map((task) => {
                const isRecurringTask =
                  task.frequencyPeriod !== FreqPeriod.Once &&
                  task.frequencyPeriod !== FreqPeriod.Unknown;
                if (isRecurringTask) {
                  return (
                    <Flex
                      key={`care-plan-sg-task-${task.modelId}`}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{
                        fontWeight: "normal",
                        _hover: { fontWeight: "bold" }
                      }}
                    >
                      <Text>{task.name}</Text>
                      <Flex>
                        <Text>
                          {Math.round(
                            (task.taskCompletionDates.length /
                              task.expectedCount) *
                              100
                          )}
                          % Complete
                        </Text>
                        <TaskModal task={task} />
                      </Flex>
                    </Flex>
                  );
                } else {
                  const latestStatus = getLastTaskStatus(task);
                  return (
                    <Flex
                      key={`care-plan-hm-task-${task.modelId}`}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{
                        fontWeight: "normal",
                        _hover: { fontWeight: "bold" }
                      }}
                    >
                      <Text>{task.name}</Text>
                      <Box>
                        {new Date() < task.dueDate! &&
                        task.expectedCount > task.taskCompletionDates.length
                          ? TaskStatusBadges.Overdue
                          : TaskStatusBadges[latestStatus?.status ?? "New"]}
                        <TaskModal task={task} />
                      </Box>
                    </Flex>
                  );
                }
              })
            )}
          </Card>
        </>
      )}
    </Flex>
  );
};

export default CarePlanDetails;

const getLastTaskStatus = (task: ClientTask) => {
  return (
    task.statusChanges?.sort((a, b) => {
      return a.date <= b.date ? 1 : -1;
    })[0] ?? null
  );
};

const TaskModal = ({ task }: { task: ClientTask }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: editFormOpen, onToggle: editFormToggle } = useDisclosure();
  const toast = useToast();
  const isRecurringTask =
    task.frequencyPeriod !== FreqPeriod.Once &&
    task.frequencyPeriod !== FreqPeriod.Unknown;
  const currentTaskStatus = getLastTaskStatus(task);
  const updateTaskStatus = async (status: TaskStatus) => {
    const clientTaskService = new ClientTaskService();
    const updatedTask = task;
    // create a new task status change
    updatedTask.statusChanges.push(
      new TaskStatusChange({ status, date: new Date() })
    );
    if (
      status === TaskStatus.Canceled ||
      status === TaskStatus.Finished ||
      status === TaskStatus.OnHold
    ) {
      // if task is stopped early for any reason, update expected count to current completion count -- expected count will be updated later if it is ever restarted (only in case of OnHold)
      updatedTask.expectedCount = task.taskCompletionDates.length;
    }
    await clientTaskService.updateTask(updatedTask).then(() => {
      toast({ status: "success", description: "Task updated successfully" });
      onToggle();
    });
  };
  return (
    <>
      <IconButton
        ml={".5rem"}
        aria-label='edit-task'
        icon={<TbEdit size={18} />}
        size='xs'
        variant='unstyled'
        onClick={() => {
          onToggle();
        }}
        cursor={"pointer"}
      ></IconButton>
      <Modal isOpen={isOpen} onClose={onToggle} size={"lg"}>
        <ModalOverlay />
        {editFormOpen ? (
          <TaskForm task={task} toggleForm={editFormToggle} />
        ) : (
          <ModalContent>
            <ModalHeader
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <VStack
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Text lineHeight={"1rem"}>{task.name}</Text>
                <Text as={"i"} fontSize={"small"} fontWeight={"normal"}>
                  {isRecurringTask
                    ? `${task.frequencyCount} / ${
                        task.frequencyPeriod
                      } until ${DateTime.fromJSDate(task.dueDate!).toFormat(
                        "MM/dd/yy"
                      )}`
                    : `Due ${DateTime.fromJSDate(task.dueDate!).toFormat(
                        "MM/dd/yy"
                      )}`}
                </Text>
              </VStack>
              <VStack justifyContent={"space-between"} alignItems={"flex-end"}>
                <CloseButton h={3} w={3} onClick={onToggle} />
              </VStack>
            </ModalHeader>
            <ModalBody py={0}>
              <Text fontWeight={"normal"}>{task.description}</Text>
              {isRecurringTask ? (
                task.taskCompletionDates.length > 0 ? (
                  <VStack
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    mt={2}
                    border={".5px solid"}
                    p={2}
                    rounded='md'
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      w={"full"}
                    >
                      <Text as='b'>Task Log</Text>
                      <Text color={"GrayText"} as={"i"} fontSize={"small"}>
                        {task.taskCompletionDates.length}/{task.expectedCount}{" "}
                        Complete
                      </Text>
                    </Flex>
                    {task.taskCompletionDates?.toReversed().map((date, i) => (
                      <Text
                        as={"i"}
                        lineHeight={"1em"}
                        key={`task-${task.modelId}-completion-${i}`}
                      >
                        Completed on{" "}
                        {DateTime.fromJSDate(date!).toFormat("MM/dd/yy")}
                      </Text>
                    ))}
                  </VStack>
                ) : (
                  <Center mt={2} bg={"gray.200"} p={4}>
                    <Text as={"i"} textAlign={"center"} color={"grey"}>
                      No task completions
                    </Text>
                  </Center>
                )
              ) : (
                <></>
              )}
            </ModalBody>
            <ModalFooter display={"flex"} justifyContent={"space-between"}>
              <Flex alignItems={"center"}>
                <Tooltip label='Edit this task' hasArrow>
                  <IconButton
                    icon={<TbEditCircle size={18} />}
                    isRound
                    aria-label='edit-task'
                    variant={"unstyled"}
                    size={"xs"}
                    sx={{
                      _hover: {
                        color: "#4299e1" // Blue400
                      }
                    }}
                    onClick={editFormToggle}
                  />
                </Tooltip>
                <Tooltip label='Mark as complete' hasArrow>
                  <IconButton
                    icon={<TbCircleCheck size={18} />}
                    isRound
                    aria-label='edit-task'
                    variant={"unstyled"}
                    size={"xs"}
                    sx={{
                      _hover: {
                        color: "#38A169" // Green500
                      }
                    }}
                    onClick={() => updateTaskStatus(TaskStatus.Finished)}
                  />
                </Tooltip>
                <Tooltip label='Place task on hold' hasArrow>
                  <IconButton
                    icon={<TbParkingCircle size={18} />}
                    isRound
                    aria-label='edit-task'
                    variant={"unstyled"}
                    size={"xs"}
                    sx={{
                      _hover: {
                        color: "#D69E2E" // Yellow500
                      }
                    }}
                    onClick={() => updateTaskStatus(TaskStatus.OnHold)}
                  />
                </Tooltip>
                <Tooltip label='Cancel this task' hasArrow>
                  <IconButton
                    icon={<TbCircleX size={18} />}
                    isRound
                    aria-label='edit-task'
                    variant={"unstyled"}
                    size={"xs"}
                    sx={{
                      _hover: {
                        color: "#F56565" // Red400
                      }
                    }}
                    onClick={() => updateTaskStatus(TaskStatus.Canceled)}
                  />
                </Tooltip>
              </Flex>
              {currentTaskStatus && TaskStatusBadges[currentTaskStatus.status]}
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </>
  );
};

const TaskForm = ({
  task,
  toggleForm
}: {
  task: ClientTask;
  toggleForm: () => void;
}) => {
  const toast = useToast();
  const { handleSubmit, register } = useForm({
    defaultValues: {
      name: task.name,
      description: task.description,
      dueDate: DateTime.fromJSDate(task.dueDate!).toFormat("yyyy-MM-dd")
    }
  });
  const submissionHandler = async (data: {
    name: string;
    description: string;
    dueDate: string;
  }) => {
    // TODO: Figure out why fields aren't dirty here
    // const { dirtyFields } = formState;
    // const { name, description, dueDate } = dirtyFields;
    // const hasDirtyFields = !!name || !!description || !!dueDate;
    // console.log({ dirtyFields });
    task.name = data.name;
    task.description = data.description;
    task.dueDate = new Date(data.dueDate);
    if (task.frequencyPeriod !== FreqPeriod.Once) {
      // if recurring task, recalculate expected from new dueDate
      task.expectedCount = calculateExpectedCount(
        task.createdDate ?? new Date(),
        new Date(data.dueDate),
        task.frequencyCount,
        task.frequencyPeriod
      );
    }
    const clientTaskService = new ClientTaskService();
    await clientTaskService.updateTask(task).then(() => {
      toast({ status: "success", description: "Task updated!" });
      toggleForm();
    });
    // }
  };
  return (
    <ModalContent>
      <form onSubmit={handleSubmit(submissionHandler)}>
        <ModalHeader>Edit Task</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input {...register("name")} />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input {...register("description")} />
          </FormControl>
          <FormControl>
            <FormLabel>Due Date</FormLabel>
            <Input type={"date"} {...register("dueDate")} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleForm}>Cancel</Button>
          <Button type='submit'>Submit</Button>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

const TaskStatusBadges = {
  New: <Badge variant={"outline"}>Not Started</Badge>,
  InProgress: (
    <Badge variant={"outline"} colorScheme='blue'>
      In Progress
    </Badge>
  ),
  OnHold: (
    <Badge variant={"outline"} colorScheme='yellow'>
      On Hold
    </Badge>
  ),
  Finished: (
    <Badge variant={"outline"} colorScheme='green'>
      Complete
    </Badge>
  ),
  Canceled: <Badge variant={"outline"}>Canceled</Badge>,
  Overdue: (
    <Badge variant={"outline"} colorScheme='red'>
      Overdue
    </Badge>
  ),
  Unknown: <Badge variant={"outline"}>Unknown</Badge>
};
