export interface IStripeRemediationItemData {
    description: string;
    dueDate: Date | null;
}

export class StripeRemediationItem {
    description: string;
    dueDate: Date | null;

    constructor({
        description,
        dueDate,
    }: IStripeRemediationItemData) {
        this.description = description;
        this.dueDate = dueDate;
    }

    static fromMap(data: { [key: string]: any }): StripeRemediationItem {
        return new StripeRemediationItem({
            description: data["description"] ?? "",
            dueDate: data["dueDate"] ? new Date(data["dueDate"]) : null, 
        });
    }
    toJson(): { [key: string]: any } {
        return {
            "description": this.description,
            "dueDate": this.dueDate?.toISOString() ?? null,
        };
    }
}