import { DocumentSnapshot } from "firebase/firestore";

import { BillableItemService } from "../services/billable-item-service";
import { BillStatusChange } from "./bill-status-change";
import { StripePmtStatusChange } from "./stripe-pmt-status-change";
import { UserName } from "./user-name";

export interface IBillableItemData {
  modelId: string;
  billableEventId: string;
  clientId: string;
  clientName: UserName;
  placeBasedCareMRN: string;
  serviceDate: Date;
  billableItemBaseId: string;
  placeBasedCareProvId: string;
  billingCode: string;
  description: string;
  amount: number; // in cents (e.g. 1500 = $15.00)
  barberId: string | null;
  payerId: string | null;
  payerPolicyNumber: string | null;
  billStatusChanges: BillStatusChange[];
  stripePmtStatusChanges: StripePmtStatusChange[];
}

export class BillableItem {
  modelId: string;
  billableEventId: string;
  clientId: string;
  clientName: UserName;
  placeBasedCareMRN: string;
  serviceDate: Date;
  billableItemBaseId: string;
  placeBasedCareProvId: string;
  billingCode: string;
  description: string;
  amount: number; // in cents (e.g. 1500 = $15.00)
  barberId: string | null;
  payerId: string | null;
  payerPolicyNumber: string | null;
  billStatusChanges: BillStatusChange[];
  stripePmtStatusChanges: StripePmtStatusChange[];

  constructor({
    modelId,
    billableEventId,
    clientId,
    clientName,
    placeBasedCareMRN,
    serviceDate,
    billableItemBaseId,
    placeBasedCareProvId,
    billingCode,
    description,
    amount,
    barberId,
    payerId,
    payerPolicyNumber,
    billStatusChanges = [],
    stripePmtStatusChanges = []
  }: IBillableItemData) {
    this.modelId = modelId;
    this.billableEventId = billableEventId;
    this.clientId = clientId;
    this.clientName = clientName;
    this.placeBasedCareMRN = placeBasedCareMRN;
    this.serviceDate = serviceDate;
    this.billableItemBaseId = billableItemBaseId;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.billingCode = billingCode;
    this.description = description;
    this.amount = amount;
    this.barberId = barberId;
    this.payerId = payerId;
    this.payerPolicyNumber = payerPolicyNumber;
    this.billStatusChanges = billStatusChanges;
    this.stripePmtStatusChanges = stripePmtStatusChanges;
  }

  async updateDb(): Promise<void> {
    const db = new BillableItemService();
    await db.updateBillableItem(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): BillableItem {
    const data = docSnap.data() as { [key: string]: any };
    return BillableItem.fromMap(docSnap.id, data);
  }

  static fromMap(modelId: string, data: { [key: string]: any }): BillableItem {
    return new BillableItem({
      modelId,
      billableEventId: data["billableEventId"] ?? "",
      clientId: data["clientId"] ?? "",
      clientName: UserName.fromMap(data["clientName"] ?? {}),
      placeBasedCareMRN: data["placeBasedCareMRN"] ?? "",
      serviceDate: data["serviceDate"] //
        ? new Date(data["serviceDate"])
        : new Date(1, 0, 1), // default to 1/1/0001
      billableItemBaseId: data["billableItemBaseId"] ?? "",
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      billingCode: data["billingCode"] ?? "",
      description: data["description"] ?? "",
      amount: data["amount"] ?? 0,
      barberId: data["barberId"] ?? null,
      payerId: data["payerId"] ?? null,
      payerPolicyNumber: data["payerPolicyNumber"] ?? null,
      billStatusChanges: data["billStatusChanges"] //
        ? data["billStatusChanges"].map((element: any) =>
            BillStatusChange.fromMap(element)
          )
        : [],
      stripePmtStatusChanges: data["stripePmtStatusChanges"] //
        ? data["stripePmtStatusChanges"].map((element: any) =>
            StripePmtStatusChange.fromMap(element)
          )
        : []
    });
  }

  toJson(): { [key: string]: any } {
    return {
      modelId: this.modelId, // with subcollections we must save ID within the Firestore document itself
      billableEventId: this.billableEventId,
      clientId: this.clientId,
      clientName: this.clientName.toJson(),
      placeBasedCareMRN: this.placeBasedCareMRN,
      serviceDate: this.serviceDate.toISOString(),
      billableItemBaseId: this.billableItemBaseId,
      placeBasedCareProvId: this.placeBasedCareProvId,
      billingCode: this.billingCode,
      description: this.description,
      amount: this.amount,
      barberId: this.barberId,
      payerId: this.payerId,
      payerPolicyNumber: this.payerPolicyNumber,
      billStatusChanges: this.billStatusChanges.map((element) =>
        element.toJson()
      ),
      stripePmtStatusChanges: this.stripePmtStatusChanges.map((element) =>
        element.toJson()
      )
    };
  }
}
