import { useState, useEffect, useCallback } from "react";
import { ClientNoteService } from "@oben-core-web/services/client-note-service";
import { ClientNote } from "@oben-core-web/models/client-note";

const useClientNotes = (clientId: string) => {
  const [clientNotes, setClientNotes] = useState<ClientNote[]>([]);

  const fetchClientNotes = useCallback(async () => {
    const clientNoteService = new ClientNoteService();
    const notes = await clientNoteService.getAllClientNotes(clientId);
    setClientNotes(
      notes.sort((a, b) => (a.createdDate <= b.createdDate ? 1 : -1))
    );
  }, [clientId]);

  useEffect(() => {
    fetchClientNotes();
  }, [fetchClientNotes]);

  return { clientNotes, refetch: fetchClientNotes };
};

export default useClientNotes;
