import useClientCarePlans from "@/hooks/useClientCarePlans";
import useMedications from "@/hooks/useMedications";
import useTasks from "@/hooks/useTasks";
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Text,
  VStack
} from "@chakra-ui/react";
import { ClientTaskType } from "@oben-core-web/models/client-task";
import { CarePlanTransactionService } from "@oben-core-web/services/care-plan-transaction-service";
import { DateTime } from "luxon";

interface ICPWizardSummary {
  carePlanId: string;
  clientId: string;
  onSubmit?: () => void;
  onCancel?: () => void;
}

const CPWizardSummary = ({
  carePlanId,
  clientId,
  onSubmit,
  onCancel
}: ICPWizardSummary) => {
  const { carePlans } = useClientCarePlans(clientId);
  const carePlan = carePlans.find((cp) => cp.modelId === carePlanId);
  const latestBPGoal = carePlan?.bpGoals.sort((a, b) =>
    a.setDate >= b.setDate ? -1 : 1
  )[0];
  const { medications } = useMedications({ clientId, carePlanId });
  const clientTasks = useTasks({ clientId, carePlanId });

  const smartGoalTasks = clientTasks.filter(
    (t) => t.taskType === ClientTaskType.SmartGoal
  );
  const healthMaintenanceTasks = clientTasks.filter(
    (t) => t.taskType === ClientTaskType.HealthcareAction
  );
  const homeMonitoringTask = clientTasks
    .sort((a, b) => (a.createdDate! >= b.createdDate! ? -1 : 1))
    .filter((t) => t.taskType === ClientTaskType.HomeBPMonitoring)[0];
  const pcpFollowUpTask = clientTasks
    .sort((a, b) => (a.createdDate! >= b.createdDate! ? -1 : 1))
    .filter((t) => t.taskType === ClientTaskType.HealthcareAction)[0];

  const setCarePlanAsActive = async () => {
    await CarePlanTransactionService.activateCarePlan({
      clientId,
      carePlanId
    }).then(() => {
      onSubmit && onSubmit();
    });
  };

  return (
    <VStack alignItems={"flex-start"} w={"full"}>
      <Text fontSize={"x-large"}>Blood Pressure Goal</Text>
      <Text>
        {latestBPGoal?.systolic}/{latestBPGoal?.diastolic}
      </Text>

      <Text fontSize={"x-large"} as={"h2"}>
        Medications
      </Text>
      <VStack justifyContent={"flex-start"} alignItems={"flex-start"}>
        {medications.map((med, i) => (
          <Box key={`summary-med-${i}`}>
            <Flex
              direction={"column"}
              mb={2}
              pl={"1rem"}
              borderLeft={"1px solid"}
            >
              <Text>{`${med.name} - ${med.newDosage.strength}mg`}</Text>
              <Text fontSize={"small"}>{`${med.newDosage.frequencyCount} per ${
                med.newDosage.frequencyPeriod === "Daily" ? "day" : "week"
              } for ${med.newDosage.durationCount} days`}</Text>
            </Flex>
            {i === medications.length - 1 ? null : <Divider />}
          </Box>
        ))}
      </VStack>

      <Text fontSize={"x-large"} as={"h2"}>
        Health Maintenance Goals
      </Text>
      <VStack alignItems={"flex-start"}>
        {healthMaintenanceTasks.map((task, i) => (
          <Box key={`summary-hm-task-${i}`}>
            <Flex
              direction={"column"}
              mb={2}
              pl={"1rem"}
              borderLeft={"1px solid"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
            >
              <Text fontSize={"large"} lineHeight={"1.5em"} as='b'>
                {task.name}
              </Text>

              <Text fontSize={"medium"}>{`${task.description}`}</Text>
              <Text lineHeight={"1.5em"} fontSize={"small"}>
                {task.dueDate
                  ? `Due ${DateTime.fromJSDate(task.dueDate).toFormat(
                      "MM/dd/yyyy"
                    )}`
                  : ""}
              </Text>
            </Flex>
            {i === smartGoalTasks.length - 1 ? null : <Divider />}
          </Box>
        ))}
      </VStack>

      <Text fontSize={"x-large"} as={"h2"}>
        Smart Goals
      </Text>
      <VStack>
        {smartGoalTasks.map((task, i) => (
          <Box key={`summary-sg-task-${i}`}>
            <Flex
              direction={"column"}
              mb={2}
              borderLeft={"1px solid"}
              pl={"1rem"}
            >
              <Text fontSize={"large"}>{`${task.name}`}</Text>
              <Text fontSize={"medium"}>{`${task.description}`}</Text>
              <Text fontSize={"small"}>{`${
                task.frequencyCount == 1
                  ? task.frequencyCount + " time"
                  : task.frequencyCount + " times"
              } per ${task.frequencyPeriod === "Daily" ? "day" : "week"} ${
                task.dueDate
                  ? "until " +
                    DateTime.fromJSDate(task.dueDate).toFormat("MM/dd/yyyy")
                  : ""
              }`}</Text>
            </Flex>
            {i === smartGoalTasks.length - 1 ? null : <Divider />}
          </Box>
        ))}
      </VStack>

      <Text fontSize={"x-large"} as={"h2"}>
        Follow up and Monitoring
      </Text>
      <VStack alignItems={"flex-start"}>
        {!!homeMonitoringTask && (
          <Flex direction={"column"} borderLeft={"1px solid"} pl={"1rem"}>
            <Text fontSize={"large"}>Home blood pressure monitoring</Text>
            <Text fontSize={"small"}>{`${
              homeMonitoringTask.frequencyCount == 1
                ? homeMonitoringTask.frequencyCount + " time"
                : homeMonitoringTask.frequencyCount + " times"
            } per ${
              homeMonitoringTask.frequencyPeriod === "Daily" ? "day" : "week"
            } ${
              homeMonitoringTask.dueDate
                ? "until " +
                  DateTime.fromJSDate(homeMonitoringTask.dueDate).toFormat(
                    "MM/dd/yyyy"
                  )
                : ""
            }`}</Text>
          </Flex>
        )}

        {!!pcpFollowUpTask && (
          <Flex
            direction={"column"}
            borderLeft={"1px solid"}
            pl={"1rem"}
            mb={2}
          >
            <Text fontSize={"large"}>
              Visit your primary care physician by{" "}
              {DateTime.fromJSDate(pcpFollowUpTask.dueDate!).toFormat(
                "MM/dd/yyyy"
              )}
            </Text>
          </Flex>
        )}
      </VStack>

      <HStack mt={"2rem"} w={"full"} justifyContent={"space-between"}>
        {onCancel && <Button onClick={onCancel}>Previous</Button>}
        {onSubmit && <Button onClick={setCarePlanAsActive}>Done</Button>}
      </HStack>
    </VStack>
  );
};

export default CPWizardSummary;
