import useClientTasks from "@/hooks/useTasks";
import _ from "lodash";
import ReactApexChart from "react-apexcharts";

interface ITaskCompletionChart {
  clientId: string;
  carePlanId: string;
}

const TaskCompletionChart = ({
  clientId,
  carePlanId
}: ITaskCompletionChart) => {
  const clientTasks = useClientTasks({ clientId, carePlanId });
  const tasksByCompletion = _.groupBy(clientTasks, (task) => {
    const isOverdue =
      task.expectedCount === 1 &&
      task.taskCompletionDates.length === 0 &&
      new Date() > task.dueDate!;
    const isComplete = task.expectedCount === task.taskCompletionDates.length;
    return isOverdue ? "Overdue" : isComplete ? "Completed" : "In Progress";
  });
  const data = Object.values(tasksByCompletion).map((tasks) => tasks.length);
  const series = Object.keys(tasksByCompletion);
  return (
    <ReactApexChart
      height={"100%"}
      width={"100%"}
      options={{
        labels: series,
        legend: {
          show: false
        },
        chart: {
          type: "pie",
          sparkline: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (val, opts) => {
            return series[opts.seriesIndex];
          }
        },
        tooltip: {
          y: {
            formatter: (val, { seriesIndex }) => `${data[seriesIndex]} tasks`
          }
        },
        title: {
          align: "center",
          text: "Client Tasks",
          margin: 0,
          floating: true
        },
        plotOptions: {
          pie: {
            customScale: 0.8
          }
        }
      }}
      series={data}
      type='pie'
    />
  );
};

export default TaskCompletionChart;
