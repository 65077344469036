import { Color, Colors } from './color';

export enum Flavor {
  DEV,
  ALPHA,
  BETA,
  PROD,
}

export enum WellnessCategory {
  Exercise = 'Exercise',
  Nutrition = 'Nutrition',
  Stress = 'Stress',
}

export enum ResponseType {
  MythOrFact = 'MythOrFact',
  MultiSelection = 'MultiSelection',
  SingleSelection = 'SingleSelection',
  TextInput = 'TextInput',
}

export enum StepType {
  Spacer = 'Spacer',
  Question = 'Question',
  Dyk = 'Dyk',
  Content = 'Content',
}

export enum ContentType {
  Audio = 'Audio',
  Image = 'Image',
  Markdown = 'Markdown',
  Video = 'Video',
}

export enum NetworkAssetItem {
  WelcomeMessage = 'WelcomeMessage',
  BpGuide = 'BpGuide',
  PatientAppDownloadQRCode = 'PatientAppDownloadQRCode',
}

//* Do not change the order of the BpRange enum values !!!
//* The order is important for the lookup() method to work correctly.
export enum BpRange {
  Crisis = 'Crisis',
  VeryHigh = 'VeryHigh',
  High = 'High',
  Elevated = 'Elevated',
  Normal = 'Normal',
  Low = 'Low',
  Error = 'Error',
}

interface IBpRangeData {
  limitSys: number;
  limitDia: number;
  label: string;
  stage: string;
  textColor: Color;
  backgroundColor: Color;
  foregroundColor: Color;
}
export const bpRangeData: Record<BpRange, IBpRangeData> = {
  [BpRange.Crisis]: {
    limitSys: 180,
    limitDia: 120,
    label: 'label_crisis',
    stage: 'label_stage_crisis',
    textColor: new Color(0xFFD500F9), // purpleAccent.shade400,
    backgroundColor: new Color(0xFFEA80FC), // purpleAccent.shade100,
    foregroundColor: Colors.white,
  },
  [BpRange.VeryHigh]: {
    limitSys: 140,
    limitDia: 90,
    label: 'label_very_high',
    stage: 'label_stage_2',
    textColor: new Color(0xFFF44336), // red.shade500,
    backgroundColor: new Color(0xFFEF9A9A), // red.shade200,
    foregroundColor: Colors.black,
  },
  [BpRange.High]: {
    limitSys: 130,
    limitDia: 80,
    label: 'label_high',
    stage: 'label_stage_1',
    textColor: new Color(0xFFFF9800), // orange.shade500,
    backgroundColor: new Color(0xFFFFCC80), // orange.shade200,
    foregroundColor: Colors.black,
  },
  [BpRange.Elevated]: {
    limitSys: 120,
    limitDia: 60,
    label: 'label_elevated',
    stage: 'label_stage_elevated',
    textColor: new Color(0xFFFDD835), // yellow.shade600,
    backgroundColor: new Color(0xFFFFF9C4), // yellow.shade100,
    foregroundColor: Colors.black,
  },
  [BpRange.Normal]: {
    limitSys: 90,
    limitDia: 60,
    label: 'label_normal',
    stage: 'label_stage_normal',
    textColor: new Color(0xFF4CAF50), // green.shade500,
    backgroundColor: new Color(0xFFC8E6C9), // green.shade100,
    foregroundColor: Colors.black,
  },
  [BpRange.Low]: {
    limitSys: 60,
    limitDia: 20,
    label: 'label_low',
    stage: 'label_stage_low',
    textColor: new Color(0xFF2196F3), // blue.shade500,
    backgroundColor: new Color(0xFFBBDE97), // blue.shade100,
    foregroundColor: Colors.black,
  },
  [BpRange.Error]: {
    limitSys: 0,
    limitDia: 0,
    label: 'label_error',
    stage: 'label_error',
    textColor: Colors.black,
    backgroundColor: Colors.black,
    foregroundColor: Colors.white,
  },
};

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class BpRangeHelper {
  static valueOf(searchString: string | null): BpRange | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(BpRange).includes(searchString as BpRange)) //
      ? searchString as BpRange
      : null;
  }
  
  static lookup(systolic: number | null, diastolic: number | null): BpRange | null {
    if ((systolic === null) || (diastolic === null)) {
      return null;
    }
    Object.values(BpRange).find((range) => {
      const data = bpRangeData[range as BpRange];
      return (systolic >= data.limitSys) || (diastolic >= data.limitDia);
    });
    return null;
  }
}

export enum OutreachStatus {
  New = 'label_new',
  Normal = 'label_normal',
  Pending = 'label_pending',
}

interface IOutreachStatusData {
  color: Color;
  label: string;
}

export const outreachStatusData: Record<OutreachStatus, IOutreachStatusData> = {
  [OutreachStatus.New]: {
    color: Colors.red,
    label: 'label_new',
  },
  [OutreachStatus.Normal]: {
    color: Colors.green,
    label: 'label_normal',
  },
  [OutreachStatus.Pending]: {
    color: Colors.blue,
    label: 'label_pending',
  },
};

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class OutreachStatusHelper {
  static valueOf(searchString: string | null): OutreachStatus | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(OutreachStatus).includes(searchString as OutreachStatus)) //
      ? searchString as OutreachStatus
      : null;
  }
}

export enum UserType {
  Unknown = 'Unknown',
  Client = 'Client',
  Barber = 'Barber',
  Pharmacist = 'Pharmacist',
  ProgramManager = 'ProgramManager',
  Physician = 'Physician',
  ObenAdmin = 'ObenAdmin',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class UserTypeHelper {
  static valueOf(searchString: string | null): UserType | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(UserType).includes(searchString as UserType)) //
      ? searchString as UserType
      : null;
  }
}

export enum FreqPeriod {
  Unknown = 'Unknown',
  Once = 'Once',
  Daily = 'Daily',
  Weekly = 'Weekly',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class FreqPeriodHelper {
  static valueOf(searchString: string | null): FreqPeriod | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(FreqPeriod).includes(searchString as FreqPeriod)) //
      ? searchString as FreqPeriod
      : null;
  }
}

export enum EnrollmentEligibility {
  Qualified = 'Qualified',
  Pending = 'Pending',
  NotQualified = 'NotQualified',
}


export enum Sex {
  Unknown = "Unknown",
  Male = "Male",
  Female = "Female",
  Intersex = "Intersex",
  PreferNotToSay = "PreferNotToSay",
}

export class SexHelper {
  static valueOf(searchString: string | null): Sex | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(Sex).includes(searchString as Sex)) //
      ? searchString as Sex
      : null;
  }
}

export enum Race {
  Unknown = "Unknown",
  Black = "Black",
  White = "White",
  Other = "Other",
}

export class RaceHelper {
  static valueOf(searchString: string | null): Race | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(Race).includes(searchString as Race)) //
      ? searchString as Race
      : null;
  }
}
