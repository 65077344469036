import {
  CollectionReference,
  collection,
  DocumentData,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { ClientTaskBase } from '../models/client-task-base';
import { GaLog } from '../utils/ga-log';

export class ClientTaskBaseService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = 'clientTaskBases';
    this._collectionReference = collection(Database, this._collectionName);
  }

  // filter on placeBasedCareProvId to limit visibility into other programs
  // TODO: Implement additional/optional filtering
  async getAllClientTaskBases(placeBasedCareProvId: string): Promise<ClientTaskBase[]> {
    const queryRef = await query(
      this._collectionReference,
      where('placeBasedCareProvId', '==', placeBasedCareProvId)
    );
    try {
      const qSnapshot = await getDocs(queryRef);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => ClientTaskBase.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getTaskBase(taskBaseId: string): Promise<ClientTaskBase> {
    try {
      const docRef = doc(this._collectionReference, taskBaseId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`BP Reading not found: ${taskBaseId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return ClientTaskBase.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addTaskBase(taskBase: ClientTaskBase): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, taskBase.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateTaskBase(taskBase: ClientTaskBase): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, taskBase.id);
      await updateDoc(docRef, taskBase.toJson());
      GaLog.updateDocument(this._collectionReference.path, taskBase.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // ClientTaskBases can only be updated, not deleted
  // async deleteTaskBase(taskBaseId: string): Promise<void> {
  //   try {
  //     const docRef = doc(this._collectionReference, taskBaseId);
  //     await deleteDoc(docRef);
  //     GaLog.deleteDocument(this._collectionReference.path, taskBaseId);
  //     return;
  //   } catch (error) {
  //     GaLog.deleteError(this._collectionReference.path, error);
  //     throw error;
  //   }
  // }
}
