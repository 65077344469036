import { DocumentSnapshot } from 'firebase/firestore';

import { UserType, UserTypeHelper } from '../constants/core-enums';
import { MedicationService } from '../services/medication-service';
import { MedDosage } from './med-dosage';

/// MedAdherence records are maintained in a subcollection on CarePlan.
///* Due to a Firestore limitation, when working with subcollections, for queries to work we must also store
///* the Firestore document id within the document itself (stored as "modelId").
export interface IMedicationData {
  modelId: string;
  clientId: string;
  carePlanId: string;
  medBaseId: string;
  placeBasedCareProvId: string;
  name: string;
  oldDosage: MedDosage;
  newDosage: MedDosage;
  prescriberId: string;
  prescriberType: UserType;
  rxStartDate: Date | null;
  rxCancelDate: Date | null;
}

export class Medication {
  modelId: string;
  clientId: string;
  carePlanId: string;
  medBaseId: string;
  placeBasedCareProvId: string;
  name: string;
  oldDosage: MedDosage;
  newDosage: MedDosage;
  prescriberId: string;
  prescriberType: UserType;
  rxStartDate: Date | null;
  rxCancelDate: Date | null;

  constructor({
    modelId,
    clientId,
    carePlanId,
    medBaseId,
    placeBasedCareProvId,
    name,
    oldDosage,
    newDosage,
    prescriberId,
    prescriberType,
    rxStartDate,
    rxCancelDate,
  }: IMedicationData) {
    this.modelId = modelId;
    this.clientId = clientId;
    this.carePlanId = carePlanId;
    this.medBaseId = medBaseId;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.name = name;
    this.oldDosage = oldDosage;
    this.newDosage = newDosage;
    this.prescriberId = prescriberId;
    this.prescriberType = prescriberType;
    this.rxStartDate = rxStartDate;
    this.rxCancelDate = rxCancelDate;
  }

  // True if there is any change in dosage
  get isIntensification(): boolean {
    return (this.oldDosage.strength != this.newDosage.strength) ||
      (this.oldDosage.frequencyCount != this.newDosage.frequencyCount) ||
      (this.oldDosage.frequencyPeriod != this.newDosage.frequencyPeriod) ||
      (this.oldDosage.durationCount != this.newDosage.durationCount);
  }

  async updateDb(): Promise<void> {
    const db = new MedicationService();
    await db.updateMedication(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): Medication {
    const data = docSnap.data() as { [key: string]: any };
    return Medication.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): Medication {
    return new Medication({
      modelId: data["modelId"] ?? id, //  with subcollections we prefer to use the id that is stored within the document itself
      clientId: data["clientId"] ?? "",
      carePlanId: data["carePlanId"] ?? "",
      medBaseId: data["medBaseId"] ?? "",
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      name: data["name"] ?? "",
      oldDosage: MedDosage.fromMap(data["oldDosage"] ?? {}),
      newDosage: MedDosage.fromMap(data["newDosage"] ?? {}),
      prescriberId: data["prescriberId"] ?? "",
      prescriberType: UserTypeHelper.valueOf(data["prescriberType"] ?? "") ?? UserType.Unknown,
      rxStartDate: data["rxStartDate"] ? new Date(data["rxStartDate"]) : null,
      rxCancelDate: data["rxCancelDate"] ? new Date(data["rxCancelDate"]) : null,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      "modelId": this.modelId, // with subcollections we must save ID within the Firestore document itself
      "clientId": this.clientId,
      "carePlanId": this.carePlanId,
      "medBaseId": this.medBaseId,
      "placeBasedCareProvId": this.placeBasedCareProvId,
      "name": this.name,
      "oldDosage": this.oldDosage.toJson(),
      "newDosage": this.newDosage.toJson(),
      "prescriberId": this.prescriberId,
      "prescriberType": this.prescriberType,
      "rxStartDate": this.rxStartDate?.toISOString(),
      "rxCancelDate": this.rxCancelDate?.toISOString() ?? null,
    };
  }
}
