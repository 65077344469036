import { DocumentSnapshot } from 'firebase/firestore';

import { BarbershopService } from '../services/barbershop-service';
import { Address } from './address';

export interface IBarbershopData {
  // common fields for all "entities"
  id: string;
  businessName: string;
  address: Address;
  enabled: boolean;
  // barbershop-specific fields
  screeningsGoal: number;
  shouldExecuteCloudFunction: boolean; // set by Cloud function
  shopRanking: number | null; // set by Cloud Functions. Will be null if function has never run
  totalScreenings: number | null; // set by Cloud Functions. Will be null if function has never run
  currentMonthScreenings: number | null; // set by Cloud Functions. Will be null if function has never run
  currentMonthQualified: number | null; // set by Cloud Functions. Will be null if function has never run
  placeBasedCareProviderIds: string[];
}

export class Barbershop {
  id: string;
  businessName: string;
  address: Address;
  enabled: boolean;
  screeningsGoal: number;
  shouldExecuteCloudFunction: boolean; // set by Cloud function
  shopRanking: number | null; // set by Cloud Functions. Will be null if function has never run
  totalScreenings: number | null; // set by Cloud Functions. Will be null if function has never run
  currentMonthScreenings: number | null; // set by Cloud Functions. Will be null if function has never run
  currentMonthQualified: number | null; // set by Cloud Functions. Will be null if function has never run
  placeBasedCareProviderIds: string[];
  
  constructor({
    id,
    businessName,
    address,
    enabled,
    screeningsGoal,
    shouldExecuteCloudFunction,
    shopRanking,
    totalScreenings,
    currentMonthScreenings,
    currentMonthQualified,
    placeBasedCareProviderIds, 
  }: IBarbershopData) {
    this.id = id;
    this.businessName = businessName;
    this.address = address;
    this.enabled = enabled;
    this.screeningsGoal = screeningsGoal;
    this.shouldExecuteCloudFunction = shouldExecuteCloudFunction;
    this.shopRanking = shopRanking;
    this.totalScreenings = totalScreenings;
    this.currentMonthScreenings = currentMonthScreenings;
    this.currentMonthQualified = currentMonthQualified;
    this.placeBasedCareProviderIds = placeBasedCareProviderIds; 
  }

  get remainingScreenings(): number {
    return this.screeningsGoal - (this.currentMonthScreenings ?? 0);
  }

  async updateDb(): Promise<void> {
    const db = new BarbershopService();
    await db.updateBarbershop(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): Barbershop {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return Barbershop.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): Barbershop {
    return new Barbershop({
      id: id,
      businessName: data["businessName"] ?? "",
      address: Address.fromMap(data["address"] ?? {}),
      enabled: data["enabled"] ?? false,
      screeningsGoal: data["screeningsGoal"] ?? 0,
      shouldExecuteCloudFunction: data["shouldExecuteCloudFunction"] ?? false,
      shopRanking: data["shopRanking"], // can be null
      totalScreenings: data["totalScreenings"], // can be null
      currentMonthScreenings: data["currentMonthScreenings"], // can be null
      currentMonthQualified: data["currentMonthQualified"], // can be null
      placeBasedCareProviderIds: data["placeBasedCareProviderIds"] ?? [],
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      "businessName": this.businessName,
      "address": this.address.toJson(),
      "enabled": this.enabled,
      "screeningsGoal": this.screeningsGoal,
      "shouldExecuteCloudFunction": this.shouldExecuteCloudFunction,
      "shopRanking": this.shopRanking,
      "totalScreenings": this.totalScreenings,
      "currentMonthScreenings": this.currentMonthScreenings,
      "currentMonthQualified": this.currentMonthQualified,
      "placeBasedCareProviderIds": this.placeBasedCareProviderIds,
    };
  }
}