import SchedulerComponent from "../molecules/Scheduler";
import SchedulerAppointmentForm from "../molecules/SchedulerAppointmentForm";
import { Box, Select, useDisclosure } from "@chakra-ui/react";
import useWebUsers from "@/hooks/useWebUsers";
import { UserType } from "@oben-core-web/constants/core-enums";
import { useCallback, useEffect, useMemo, useState } from "react";
import { WebUser } from "@oben-core-web/models/web-user";
import { DateTime } from "luxon";

const Appointments = () => {
  const [selectedWebUser, setSelectedWebUser] = useState<WebUser | null>(null);
  const { isOpen, onToggle } = useDisclosure();
  const [appointment, setAppointment] = useState<any>();
  // console.log({ appointment });
  const webUserParams = useMemo(
    () => [UserType.Pharmacist, UserType.Physician],
    []
  );
  const { webUsers } = useWebUsers(webUserParams);
  useEffect(() => {
    if (!isOpen && !!appointment) {
      setAppointment(null);
    }
  }, [isOpen, appointment]);
  const handleSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const id = e.target.value;
      if (webUsers && webUsers.length) {
        setSelectedWebUser(webUsers.find((wu) => wu.uid === id)!);
      }
    },
    [webUsers]
  );
  const setSelectedAppointment = useCallback(
    (appt: any) => {
      console.log({ appt });
      setAppointment({
        ...appt,
        startDateTime: new Date(appt.startDateTime),
        endDateTime: new Date(appt.endDateTime)
      });
      onToggle();
    },
    [onToggle]
  );
  const setSelectedDate = useCallback(
    (timeRange: { start: Date; end: Date }) => {
      const length = DateTime.fromJSDate(timeRange.end).diff(
        DateTime.fromJSDate(timeRange.start),
        "minutes"
      ).minutes;
      setAppointment({
        startDateTime: timeRange.start,
        length,
        pharmacistId: selectedWebUser?.uid
      });
      onToggle();
    },
    [onToggle, selectedWebUser]
  );

  const userOptions = useMemo(
    () =>
      webUsers.map((wu) => (
        <option key={wu.uid} value={wu.uid}>
          {wu.name.display}
        </option>
      )),
    [webUsers]
  );

  return (
    <Box p={2}>
      <Select my={2} placeholder='Select a Pharmacist' onChange={handleSelect}>
        {userOptions}
      </Select>
      <SchedulerComponent
        selectedWebUser={selectedWebUser}
        onEventClick={setSelectedAppointment}
        onDateClick={setSelectedDate}
      />
      <SchedulerAppointmentForm
        dialogOpen={isOpen}
        appointment={appointment}
        disableFormActions={false}
        toggleDialog={onToggle}
      />
    </Box>
  );
};

export default Appointments;
