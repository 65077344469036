import React from "react";
import useClientNotes from "@/hooks/useClientNotes";
import {
  Card,
  Flex,
  HStack,
  IconButton,
  VStack,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  Input,
  Button,
  Text,
  ModalOverlay,
  ModalHeader,
  Textarea,
  ModalFooter
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { EditIcon } from "@chakra-ui/icons";
import { ClientNote, IClientNoteData } from "@oben-core-web/models/client-note";
import { useForm } from "react-hook-form";
import { ClientNoteService } from "@oben-core-web/services/client-note-service";
import { UserType } from "@oben-core-web/constants/core-enums";

interface IClientNotes {
  clientId: string;
}

const NoteTypeLabels = {
  Unknown: "Miscellaneous",
  Appointment: "Appointment",
  CarePlan: "Care Plan",
  ClientTask: "Client Task",
  MedAdherence: "Medication Adherence",
  Medication: "Medication",
  General: "General"
};

const ClientNotes = ({ clientId }: IClientNotes) => {
  const { clientNotes, refetch } = useClientNotes(clientId);
  return (
    <VStack>
      {clientNotes.map((cn) => (
        <ClientNoteRow
          note={cn}
          key={`client-note-${cn.modelId}`}
          onEditSuccess={refetch}
        />
      ))}
    </VStack>
  );
};

export default ClientNotes;

const ClientNoteRow = ({
  note,
  onEditSuccess
}: {
  note: ClientNote;
  onEditSuccess: () => void;
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const { register, handleSubmit, formState } = useForm<IClientNoteData>({
    defaultValues: {
      modelId: note.modelId,
      clientId: note.clientId,
      authorId: note.authorId,
      authorType: note.authorType as UserType,
      createdDate: note.createdDate,
      noteSourceId: note.noteSourceId,
      noteType: note.noteType,
      summary: note.summary,
      details: note.details
    }
  });

  const updateNote = async (data: IClientNoteData) => {
    if (formState.dirtyFields?.summary || formState.dirtyFields?.details) {
      const note = new ClientNote({ ...data });
      const clientNoteService = new ClientNoteService();
      await clientNoteService.updateClientNote(note).then(() => {
        onEditSuccess();
        onToggle();
      });
    } else {
      onToggle();
    }
  };

  return (
    <Card w={"full"} p={2}>
      <Flex justifyContent={"space-between"} alignItems={"center"} w={"full"}>
        <Text>
          {note.summary ??
            NoteTypeLabels[note.noteType as keyof typeof NoteTypeLabels]}
        </Text>
        <HStack>
          <Text fontWeight={"normal"} as={"i"}>
            {DateTime.fromJSDate(note.createdDate).toFormat("MM/dd/yyyy")}
          </Text>
          <IconButton
            aria-label='note-icon'
            variant={"ghost"}
            icon={<EditIcon />}
            size='sm'
            onClick={onToggle}
          />
        </HStack>
      </Flex>
      <Text fontWeight={"normal"}>{note.details}</Text>
      <Modal isOpen={isOpen} onClose={onToggle}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(updateNote)}>
            <ModalHeader>Edit Note</ModalHeader>
            <ModalBody>
              <Input mb={2} {...register("summary")}></Input>
              <Textarea {...register("details")}></Textarea>
            </ModalBody>
            <ModalFooter
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Button onClick={onToggle}>Cancel</Button>
              <Button type={"submit"}>Save</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Card>
  );
};
