import { getAnalytics, logEvent } from "firebase/analytics";

export enum GaLogType {
  ReadCollection = "ReadCollection",
  ReadDocument = "ReadDocument",
  ReadError = "ReadError",
  AddDocument = "AddDocument",
  AddError = "AddError",
  UpdateDocument = "UpdateDocument",
  UpdateError = "UpdateError",
  DeleteDocument = "DeleteDocument",
  DeleteError = "DeleteError",
}

const fbAnalytics = getAnalytics();

export class GaLog {
  static readCollection(path: string, count: number, {isSubscription = false}: {isSubscription?: boolean} = {}): void {
    logEvent(fbAnalytics, GaLogType.ReadCollection, { path: path, count: count, isSubscription: isSubscription });
  }

  static readDocument(path: string, id: string, {isSubscription = false}: {isSubscription?: boolean} = {}): void {
    logEvent(fbAnalytics, GaLogType.ReadDocument, { path: path, id: id, isSubscription: isSubscription });
  }

  static readError(path: string, error: any, {isSubscription = false}: {isSubscription?: boolean} = {}): void {
    logEvent(fbAnalytics, GaLogType.ReadError, { path: path, error: error, isSubscription: isSubscription });
  }

  static addDocument(path: string, id: string): void {
    logEvent(fbAnalytics, GaLogType.AddDocument, { path: path, id: id });
  }

  static addError(path: string, error: any): void {
    logEvent(fbAnalytics, GaLogType.AddError, { path: path, error: error });
  }

  static updateDocument(path: string, id: string): void {
    logEvent(fbAnalytics, GaLogType.UpdateDocument, { path: path, id: id });
  }

  static updateError(path: string, error: any): void {
    logEvent(fbAnalytics, GaLogType.UpdateError, { path: path, error: error });
  }

  static deleteDocument(path: string, id: string): void {
    logEvent(fbAnalytics, GaLogType.DeleteDocument, { path: path, id: id });
  }

  static deleteError(path: string, error: any): void {
    logEvent(fbAnalytics, GaLogType.DeleteError, { path: path, error: error });
  }
}