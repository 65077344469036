import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
// import { RouterProvider } from 'react-router-dom';
import store from './store';
// import appRouter from './routers/AppRouter'; // Import the router configuration
import RootRouter from './routers/index';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <RootRouter />
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);
