import { UserInfo } from 'firebase/auth';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAppReducerState {
  loading: boolean;
  currentUser: UserInfo | null;
}
export const INITIAL_STATE: IAppReducerState = {
  loading: false,
  currentUser: null,
};

export type TAppAction =
  | { type: `RESET_APP` }
  | { type: `SET_APP_LOADING`; payload: boolean }
  | { type: `SET_CURRENT_USER`; payload: UserInfo | null };

const appSlice = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      console.log('SET_APP_LOADING');
      state.loading = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<UserInfo | null>) => {
      console.log('SET_CURRENT_USER');
      state.currentUser = action.payload;
    },
    resetApp: (state) => {
      console.log('RESET_APP');
      state.currentUser = INITIAL_STATE.currentUser;
      state.loading = INITIAL_STATE.loading;
    },
  },
});

export const { setAppLoading, setCurrentUser, resetApp } = appSlice.actions;

export default appSlice.reducer;
