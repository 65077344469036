import { useState, useEffect } from "react";
import { MedAdherenceService } from "@oben-core-web/services/med-adherence-service";
import { MedAdherence } from "@oben-core-web/models/med-adherence";

const useMedAdherence = ({
  clientId,
  carePlanId
}: {
  clientId: string;
  carePlanId: string;
}) => {
  const [medAdherences, setMedAdherences] = useState<MedAdherence[]>([]);
  useEffect(() => {
    const fetchAdherences = async () => {
      const medAdherenceService = new MedAdherenceService();
      const adherences = await medAdherenceService.getClientMedAdherences(
        clientId,
        carePlanId
      );
      setMedAdherences(adherences);
    };
    fetchAdherences();
  }, [clientId, carePlanId]);
  return { medAdherences };
};

export default useMedAdherence;
