import {
  CollectionReference,
  collection,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where
} from "firebase/firestore";
import { Database } from "../../firebase";

import { BarberUser } from "../models/barber-user";
import { GaLog } from "../utils/ga-log";

export class BarberUserService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = "barbers";
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get a stream of a single BarberUser's profile
  streamBarberUser(
    uid: string,
    handleSnapshot: (docSnap: DocumentSnapshot) => void = (
      docSnap: DocumentSnapshot
    ) => {
      if (docSnap.exists()) {
        return BarberUser.fromFirestore(docSnap);
      } else {
        return BarberUser.fromMap("", {});
      }
    }
  ): {
    unsubscribe: (() => void) | undefined;
  } {
    let unsubscribe: (() => void) | undefined;
    try {
      if (uid) {
        const docRef = doc(this._collectionReference, uid);
        unsubscribe = onSnapshot(
          docRef,
          (docSnap) => {
            handleSnapshot(docSnap);
            GaLog.readDocument(this._collectionReference.path, docSnap.id, {
              isSubscription: true
            });
          },
          (error) => {
            console.error("Error in onSnapshot:", error);
            // TODO: Do we need to throw an error here?
            GaLog.readDocument(this._collectionReference.path, uid, {
              isSubscription: true
            });
          }
        );
      }
      return { unsubscribe };
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error, {
        isSubscription: true
      });
      throw error;
    }
  }

  // TODO: include filter on ProgramId or some other identifier so that teams don't have visibility into staff in other programs
  async getAllBarberUsers(): Promise<BarberUser[]> {
    try {
      const qSnapshot = await getDocs(this._collectionReference);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        BarberUser.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getBarbersByPlaceBasedCareProvId(
    placeBasedCareProvId: string
  ): Promise<BarberUser[]> {
    try {
      const barberQuery = query(
        this._collectionReference,
        where("placeBasedCareProvIds", "array-contains", placeBasedCareProvId)
      );
      const qSnapshot = await getDocs(barberQuery);
      GaLog.readCollection(
        this._collectionReference.path,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        BarberUser.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // confirm that a BarberUser exists
  async validateBarberUser(uid: string): Promise<boolean> {
    try {
      const docRef = doc(this._collectionReference, uid);
      const docSnap = await getDoc(docRef);
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return docSnap.exists();
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getBarberUser(uid: string): Promise<BarberUser> {
    try {
      const docRef = doc(this._collectionReference, uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`Barber User not found: ${uid}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return BarberUser.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addBarberUser(barberUser: BarberUser): Promise<string> {
    try {
      const docRef = await addDoc(
        this._collectionReference,
        barberUser.toJson()
      );
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateBarberUser(barberUser: BarberUser): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, barberUser.uid);
      await updateDoc(docRef, barberUser.toJson());
      GaLog.updateDocument(this._collectionReference.path, barberUser.uid);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // async deleteBarberUser(uid: string): Promise<void> {
  //   try {
  //     const docRef = doc(this._collectionReference, uid);
  //     await deleteDoc(docRef);
  //     GaLog.deleteDocument(this._collectionReference.path, uid);
  //     return;
  //   } catch (error) {
  //     GaLog.deleteError(this._collectionReference.path, error);
  //     throw error;
  //   }
  // }
}
