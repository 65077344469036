import UserMenu from '../molecules/UserProfileMenu';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import IndexSearch from '../molecules/IndexSearch';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

const AppHeader = () => {
  const navigate = useNavigate();
  return (
    <HStack justifyContent={'space-between'} alignItems={'center'}>
      <IndexSearch
        searchIndex='clients'
        prefilledText=''
        w={'30rem'}
        transformItems={(items: any[]) => {
          return items.map((item) => {
            return {
              ...item,
              id: item.objectID,
              name: item.name.first + ' ' + item.name.last,
            };
          });
        }}
        hitComponent={({ item }: any) => {
          return (
            <Flex direction={'column'}>
              <Box>
                <Text as='b'>{item.name}</Text>{' '}
                <Text as='i' fontSize={'sm'}>
                  {item.email ? ` - ${item.email}` : ''}
                </Text>
              </Box>
              {item.dob && (
                <Text as='i' fontSize='xs'>
                  Date of birth:{' '}
                  {DateTime.fromJSDate(new Date(item.dob)).toFormat('DD')}
                </Text>
              )}
            </Flex>
          );
        }}
        onResultClick={(item) => {
          navigate(`/patient/${item.id}`);
        }}
        placeholderText='Search by patient name or email'
      />
      <UserMenu />
    </HStack>
  );
};

export default AppHeader;
