import React from 'react';
import { useSelector } from 'react-redux';
import { appSelector } from '../store';
import useAuthListener from '../hooks/useAuthListener';
import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';
import { RouterProvider } from 'react-router-dom';

const App: React.FC = () => {
  useAuthListener(); // This hook should set up auth status listeners
  const { currentUser } = useSelector(appSelector);

  // Determine which router to use based on authentication status
  const router = currentUser ? AppRouter : AuthRouter;

  return <RouterProvider router={router} />;
};

export default App;
