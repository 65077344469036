import React, { useState, useEffect, useCallback, useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import {
  CalendarOptions,
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventDropArg,
  EventInput
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Box, useToast } from "@chakra-ui/react";
import { DateTime, Interval } from "luxon";
import { AppointmentService } from "@oben-core-web/services/appointment-service";
import { WebUserService } from "@oben-core-web/services/web-user-service";
import {
  Appointment,
  IAppointmentData
} from "@oben-core-web/models/appointment";
import _ from "lodash";
import { WebUser } from "@oben-core-web/models/web-user";
// import { IDefaultWorkingHoursData } from '@oben-core-web/models/default-working-hours';
import { convertToLocalTZ, convertToUTC } from "@/lib/timezone";
import useAppointments from "@/hooks/useAppointments";
import useCustomWorkingHours from "@/hooks/useCustomWorkingHours";
import { UserType } from "@oben-core-web/constants/core-enums";
import { CustomWorkingHours } from "@oben-core-web/models/custom-working-hours";

interface IScheduler {
  selectedWebUser: WebUser | null;
  onEventClick: (event: any) => void;
  onDateClick: (date: { start: Date; end: Date }) => void;
}

const SchedulerComponent = React.memo(
  ({ selectedWebUser, onEventClick, onDateClick }: IScheduler) => {
    const [weekStart, setWeekStart] = useState(
      DateTime.now().startOf("week").toJSDate()
    );
    const [weekEnd, setWeekEnd] = useState(
      DateTime.now().endOf("week").toJSDate()
    );
    // const [businessHours, setBusinessHours] = useState<any[]>([]);
    // const [events, setEvents] = useState([]);
    const [webUserIds, setWebUserIds] = useState<string[]>([]);
    const toast = useToast();

    const customHourParams = useMemo(
      () => ({
        userId: selectedWebUser?.uid ?? "",
        start: convertToUTC(weekStart),
        end: convertToUTC(weekEnd)
      }),
      [selectedWebUser, weekStart, weekEnd]
    );
    const { customWorkingHours } = useCustomWorkingHours(customHourParams);
    // const { customWorkingHours } = useCustomWorkingHours({
    //   userId: selectedWebUser?.uid ?? "",
    //   start: convertToUTC(weekStart),
    //   end: convertToUTC(weekEnd)
    // });

    const fetchWebUsers = useCallback(async () => {
      const webUserService = new WebUserService();
      return await webUserService.getAllWebUsers([
        UserType.Pharmacist,
        UserType.Physician
      ]);
    }, []);

    useEffect(() => {
      const init = async () => {
        const users = await fetchWebUsers();
        const webUserIds = users.map((wu) => wu.uid);
        // refetchAppointments(webUserIds);
        setWebUserIds(webUserIds);
      };

      init();
    }, [
      fetchWebUsers
      // refetchAppointments
    ]);
    const { appointments } = useAppointments({
      pharmacistId: selectedWebUser?.uid ?? webUserIds,
      start: weekStart,
      end: weekEnd
    });
    const pharmacistColors = useMemo(
      () =>
        appointments.reduce((acc, current) => {
          if (!acc[current.pharmacistId]) {
            acc[current.pharmacistId] =
              eventColors[
                webUserIds.findIndex((u) => u === current.pharmacistId)
              ];
          }
          return acc;
        }, {} as Record<string, string>),
      [appointments, webUserIds]
    );

    const events = useMemo(() => {
      return appointments.map((a) => ({
        ...a,
        start: a.date!,
        end: DateTime.fromJSDate(a.date!)
          .plus({ minutes: a.length })
          .toJSDate(),
        title: `${a.barbershopName} - ${a.clientName.first} ${a.clientName.last}`,
        backgroundColor: pharmacistColors[a.pharmacistId],
        borderColor: pharmacistColors[a.pharmacistId]
      }));
    }, [appointments, pharmacistColors]);

    const businessHours = useMemo(() => {
      const defaultWorkingHours =
        selectedWebUser?.defaultWorkingHours.map((dwh) => {
          const { dayOfWeek, startTime, endTime } = dwh;
          return {
            daysOfWeek: [dayOfWeek],
            startTime: DateTime.fromJSDate(weekStart)
              .set({
                hour: startTime.getHours(),
                minute: startTime.getMinutes()
              })
              .toFormat("HH:mm"),
            endTime: DateTime.fromJSDate(weekStart)
              .set({ hour: endTime.getHours(), minute: endTime.getMinutes() })
              .toFormat("HH:mm")
          };
        }) ?? [];

      const customBusinessHours = customWorkingHours.map(
        (cwh: CustomWorkingHours) => {
          const { start, end } = cwh;
          if (!start || !end) {
            console.log("Missing start or end date");
            return;
          }
          const dayOfStart = DateTime.fromJSDate(start).weekday;
          return {
            id: cwh.modelId,
            daysOfWeek: [dayOfStart],
            startTime: DateTime.fromJSDate(convertToLocalTZ(start))
              .set({ hour: start.getHours(), minute: start.getMinutes() })
              .toFormat("HH:mm"),
            endTime: DateTime.fromJSDate(convertToLocalTZ(end))
              .set({ hour: end.getHours(), minute: end.getMinutes() })
              .toFormat("HH:mm")
          };
        }
      );

      const groupedWorkingHours = _.groupBy(
        [
          ...customBusinessHours,
          ...defaultWorkingHours.map((dwh) => ({ ...dwh, id: undefined }))
        ],
        (h) => h?.daysOfWeek[0]
      );

      const newBusinessHours = _.reduce(
        groupedWorkingHours,
        (a, c) => {
          const dayWorkingHours = c.some((wh) => wh?.id)
            ? c.filter((wh) => !!wh?.id)
            : c;
          a.push(...dayWorkingHours);
          return a;
        },
        [] as any[]
      );

      // setBusinessHours(newBusinessHours);
      return newBusinessHours;
    }, [selectedWebUser, weekStart, customWorkingHours]);

    // useEffect(() => {
    //   updateBusinessHours();
    // }, [updateBusinessHours]);

    const handleDatesSet = useCallback((dateRange: DatesSetArg) => {
      setWeekStart(dateRange.start);
      setWeekEnd(dateRange.end);
      // refetchAppointments();
      // refetchCustomHours({
      //   userId: selectedWebUser?.uid,
      //   start: dateRange.start,
      //   end: dateRange.end,
      // });
    }, []);

    const handleDateSelect = useCallback(
      (selectInfo: DateSelectArg) => {
        // Handle date select logic
        console.log({ selectInfo });
        onDateClick({
          start: selectInfo.start,
          end: selectInfo.end
        });
      },
      [onDateClick]
    );

    const handleEventClick = useCallback(
      (clickInfo: EventClickArg) => {
        // Handle event click logic
        // console.log({ clickInfo });
        const { start, end } = clickInfo.event;
        if (!start || !end) {
          console.log("Click event error: Missing start or end date");
          return;
        }
        onEventClick({
          ...clickInfo.event.extendedProps,
          id: clickInfo.event.id,
          startDateTime: new Date(start),
          endDateTime: new Date(end)
        });
      },
      [onEventClick]
    );

    const handleEventDrop = useCallback(
      async (change: EventDropArg) => {
        const appointmentService = new AppointmentService();
        const oldStart = change.oldEvent.start;
        const oldEnd = change.oldEvent.end;
        const start = change.event.start;
        const end = change.event.end;
        if (!start || !end || !oldStart || !oldEnd) {
          console.log("Event drop error: Missing start or end");
          change.revert();
          return;
        }

        const pharmacistWorkingHours = businessHours ?? [];
        if (pharmacistWorkingHours.length) {
          const workingHours = pharmacistWorkingHours.filter(
            (wh) => wh.daysOfWeek[0] === DateTime.fromJSDate(start).weekday
          );
          if (!workingHours.length) {
            toast({
              title: "Pharmacist is not working that day",
              status: "error"
            });
            change.revert();
            return;
          }
          let hasValidAppointmentTimes = false;
          workingHours.forEach((wh) => {
            const [whStartHour, whStartMin] = wh.startTime.split(":");
            const [whEndHour, whEndMin] = wh.endTime.split(":");

            const workingHourInterval = Interval.fromDateTimes(
              DateTime.fromJSDate(start).set({
                hour: whStartHour,
                minute: whStartMin
              }),
              DateTime.fromJSDate(end).set({
                hour: whEndHour,
                minute: whEndMin
              })
            );
            const appointmentInterval = Interval.fromDateTimes(
              DateTime.fromJSDate(start),
              DateTime.fromJSDate(end)
            );
            const isWithinWorkingHours =
              workingHourInterval.start! <= appointmentInterval.start! &&
              workingHourInterval.end! >= appointmentInterval.end!;

            if (isWithinWorkingHours) {
              hasValidAppointmentTimes = true;
            }
          });
          if (!hasValidAppointmentTimes) {
            toast({
              title: "Pharmacist is not working at that time",
              status: "error"
            });
            change.revert();
            return;
          }
        }
        const updatedAppointment = new Appointment({
          ...(change.event.extendedProps as IAppointmentData)
        });
        const newDuration = DateTime.fromJSDate(end).diff(
          DateTime.fromJSDate(start),
          "minutes"
        ).minutes;

        updatedAppointment.date = start;
        updatedAppointment.length = newDuration;

        appointmentService.updateAppointment(updatedAppointment).then(() => {
          toast({
            title: "Appointment Rescheduled!",
            description: "Click here to undo",
            status: "success",
            isClosable: true
            // onClick: () => {
            //   // Implement undo functionality
            // },
          });
        });
        return;
      },
      [businessHours, toast]
    );

    const calendarOptions: CalendarOptions = useMemo(() => {
      return {
        plugins: [dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin],
        initialView: "timeGridWeek",
        headerToolbar: {
          right: "today prev,next",
          center: "title",
          left: "dayGridMonth,timeGridWeek"
        },
        allDaySlot: false,
        slotMinTime: "07:00",
        slotMaxTime: "23:30",
        eventOverlap: false,
        datesSet: handleDatesSet,
        events: events as EventInput,
        select: handleDateSelect,
        selectConstraint: "businessHours",
        eventClick: handleEventClick,
        editable: businessHours.length > 0,
        selectable: businessHours.length > 0,
        eventDrop: handleEventDrop, // TODO: find a workaround for this.  See github issue: https://github.com/fullcalendar/fullcalendar/issues/7619
        businessHours
      };
    }, [
      businessHours,
      events,
      handleDatesSet,
      handleDateSelect,
      handleEventClick,
      handleEventDrop
    ]);

    return (
      <Box>
        <FullCalendar {...calendarOptions} />
        {/* Additional UI elements and form handling */}
      </Box>
    );
  }
);

export default SchedulerComponent;

const eventColors = ["#454d66", "#309975", "#58b368", "#dad873", "#efeeb4"];
