import { Grid, GridItem, VStack } from "@chakra-ui/react";
import { Link, Outlet } from "react-router-dom";
import AppHeader from "./components/organisms/AppHeader";
import useCurrentUser from "./hooks/useCurrentUser";
import { UserType } from "@oben-core-web/constants/core-enums";

// Stretch TODO: view barbers and their payouts
function App() {
  const { webUser: currentUser } = useCurrentUser();
  return (
    <Grid
      templateAreas={`"nav header"
      "nav main"`}
      gridTemplateRows={"4.5rem 1fr"}
      gridTemplateColumns={"15rem 1fr"}
      h='100vh'
      gap='1'
      color='blackAlpha.700'
      fontWeight='bold'
      p={"2px"}
    >
      <GridItem border={"1px"} area={"nav"}>
        <VStack>
          <h1>Oben Health</h1>
          <Link to='/'>Home</Link>
          <Link to='/patients'>Patients</Link>
          <Link to='/appointments'>Appointments</Link>
          {currentUser?.userType === UserType.Pharmacist && (
            <Link to='/payments'>Payments</Link>
          )}
        </VStack>
      </GridItem>
      <GridItem border={"1px"} area={"header"}>
        <AppHeader />
      </GridItem>
      <GridItem border={"1px"} area={"main"} bg={"gray.50"}>
        <main style={{ height: "100%" }}>
          <Outlet /> {/* Routed components will be rendered here */}
        </main>
      </GridItem>
    </Grid>
  );
}

export default App;
