// import { useDispatch } from 'react-redux';
import { useDispatch } from "react-redux";
import { Authentication } from "../../firebase";
import { useEffectOnce } from "./useEffectOnce";
import { setCurrentUser, resetApp } from "@/reducers/appReducer";

const useAuthentication = () => {
  const dispatch = useDispatch();
  // watch for authState changes
  useEffectOnce(() => {
    Authentication.onAuthStateChanged(async (user) => {
      if (user) {
        const { displayName, email, uid, photoURL, phoneNumber, providerId } =
          user;
        dispatch(
          setCurrentUser({
            displayName,
            email,
            uid,
            photoURL,
            phoneNumber,
            providerId
          })
        );
      } else {
        dispatch(resetApp());
      }
    });
  });
};

export default useAuthentication;
