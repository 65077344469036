import { WebUserService } from "@oben-core-web/services/web-user-service";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { setCurrentUser } from "@/reducers/appReducer";
import { Authentication } from "../../firebase";
import { useToast } from "@chakra-ui/react";
import { useDispatch } from "react-redux";

const useAuthentication = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const login = async ({
    email,
    password
  }: {
    email: string;
    password: string;
  }) => {
    if (!Authentication) {
      window.alert("No auth provided");
      return;
    }
    signInWithEmailAndPassword(Authentication, email, password)
      .then(async (userCred) => {
        // TODO: provide some way for current user to log in to a specific placeBaseCareProvId
        const wus = new WebUserService();
        const isValidUser = await wus.validateWebUser(userCred.user.uid);
        if (!isValidUser) {
          // setLoginStatus(false);
          signOut(Authentication);
          toast({
            description:
              "Unable to log in.  If you believe this is an error please contact the Oben team",
            status: "error"
          });
        } else {
          const { displayName, email, uid, photoURL, phoneNumber, providerId } =
            userCred.user;
          dispatch(
            setCurrentUser({
              displayName,
              email,
              uid,
              photoURL,
              phoneNumber,
              providerId
            })
          );
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const logout = async () => {
    if (!Authentication) {
      console.log("Logout error: No authentication");
      return;
    }
    return await signOut(Authentication)
      .then(() => {
        console.log("Signed out");
        dispatch(setCurrentUser(null));
        // TODO: reset the reducer instead of just clearing the currentUser
      })
      .catch(() => {
        console.log("Unable to sign out");
      });
  };

  return { login, logout };
};

export default useAuthentication;
