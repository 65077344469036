import { UserType } from "@oben-core-web/constants/core-enums";
import { WebUser } from "@oben-core-web/models/web-user";
import { WebUserService } from "@oben-core-web/services/web-user-service";
import { useState, useEffect, useMemo } from "react";

const useWebUsers = (userTypes?: UserType[]) => {
  const [webUsers, setWebUsers] = useState<WebUser[]>([]);

  useEffect(() => {
    const fetchWebUsers = async () => {
      const webUserService = new WebUserService();
      const wus = await webUserService.getAllWebUsers(userTypes);
      setWebUsers(wus);
    };
    fetchWebUsers();
  }, [userTypes]);

  return useMemo(() => ({ webUsers }), [webUsers]);
};

export default useWebUsers;
