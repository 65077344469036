import { useEffect, useState } from "react";
import { MedicationBase } from "@oben-core-web/models/medication-base";
import { MedicationBaseService } from "@oben-core-web/services/medication-base-service";

const useMedicationBase = () => {
  const [medicationBases, setMedicationBases] = useState<MedicationBase[]>([]);
  useEffect(() => {
    let loading = false;
    const fetchMedBases = async () => {
      if (!loading) {
        console.log("CALLED FETCH MED BASES");
        const medBaseService = new MedicationBaseService();
        // TODO: provide actual placeBasedCareProvId
        const medBases = await medBaseService.getAllMedicationBases("h");
        setMedicationBases(medBases);
      }
    };
    fetchMedBases();
    return () => {
      loading = true;
    };
  }, []);
  return { medicationBases };
};

export default useMedicationBase;
