import { useEffect, useState } from 'react';
import { WebUserService } from '@oben-core-web/services/web-user-service';
import { WebUser } from '@oben-core-web/models/web-user';

const useWebUser = (id?: string) => {
  const [user, setUser] = useState<WebUser | null>(null);

  useEffect(() => {
    const webUserService = new WebUserService();
    console.log('SET UP WEBUSER STREAM');
    const { unsubscribe } = webUserService.streamWebUser(
      id ?? '',
      (docSnap) => {
        if (docSnap.exists()) {
          setUser(WebUser.fromFirestore(docSnap));
        } else {
          setUser(null);
        }
      }
    );
    return () => {
      if (unsubscribe) {
        console.log('TEAR DOWN WEBUSER STREAM');
        unsubscribe && unsubscribe();
      }
    };
  }, [id]);

  return { user };
};

export default useWebUser;
