import {
  collectionGroup,
  collection,
  DocumentData,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  Query
} from "firebase/firestore";
import { Database } from "../../firebase";

import { Medication } from "../models/medication";
import { GaLog } from "../utils/ga-log";

export class MedicationService {
  private _grandParentCollectionName: string;
  private _parentCollectionName: string;
  private _collectionName: string;
  private _collectionGroup: Query<DocumentData, DocumentData>;

  constructor() {
    this._grandParentCollectionName = "clients";
    this._parentCollectionName = "carePlans";
    this._collectionName = "medications";
    this._collectionGroup = collectionGroup(Database, this._collectionName);
  }

  // Get all medications for a specified care plan (for an individual client)
  async getClientMedications(
    clientId: string,
    carePlanId: string
  ): Promise<Medication[]> {
    const path = `${this._grandParentCollectionName}/${clientId}/${this._parentCollectionName}/${carePlanId}/${this._collectionName}`;
    try {
      const medsQuery = query(collection(Database, path));
      const qSnapshot = await getDocs(medsQuery);
      GaLog.readCollection(path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) =>
        Medication.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(path, error);
      throw error;
    }
  }

  async getMedication(medicationId: string): Promise<Medication> {
    try {
      const medQuery = query(
        this._collectionGroup,
        where("modelId", "==", medicationId)
      );
      const docs = await getDocs(medQuery);
      if (docs.empty) {
        throw new Error(`Medication not found: ${medicationId}`);
      }
      const medication = Medication.fromFirestore(docs.docs[0]);
      GaLog.readDocument(
        `${this._grandParentCollectionName}/${medication.clientId}/${this._parentCollectionName}/${medication.carePlanId}/${this._collectionName}`,
        medication.modelId
      );
      return medication;
    } catch (error) {
      GaLog.readError(
        `${this._grandParentCollectionName}/?????/${this._parentCollectionName}/?????/${this._collectionName}`,
        error
      );
      throw error;
    }
  }

  async addMedication(medication: Medication): Promise<string> {
    const subcollectionRef = collection(
      Database,
      `${this._grandParentCollectionName}/${medication.clientId}/${this._parentCollectionName}/${medication.carePlanId}/${this._collectionName}`
    );
    try {
      const docRef = await addDoc(subcollectionRef, medication.toJson());
      GaLog.addDocument(subcollectionRef.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(subcollectionRef.path, error);
      throw error;
    }
  }

  async updateMedication(medication: Medication): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._grandParentCollectionName}/${medication.clientId}/${this._parentCollectionName}/${medication.carePlanId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, medication.modelId);
      await updateDoc(docRef, medication.toJson());
      GaLog.updateDocument(subcollectionRef.path, docRef.id);
      return;
    } catch (error) {
      GaLog.updateError(subcollectionRef.path, error);
      throw error;
    }
  }

  async deleteMedication(medication: Medication): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._grandParentCollectionName}/${medication.clientId}/${this._parentCollectionName}/${medication.carePlanId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, medication.modelId);
      await deleteDoc(docRef);
      GaLog.deleteDocument(subcollectionRef.path, medication.modelId);
      return;
    } catch (error) {
      GaLog.deleteError(subcollectionRef.path, error);
      throw error;
    }
  }
}
