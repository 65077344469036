import { DocumentSnapshot } from 'firebase/firestore';

import { UserType, UserTypeHelper, FreqPeriod, FreqPeriodHelper } from '../constants/core-enums';
import { ClientTaskService } from '../services/client-task-service';
import { TaskStatusChange } from './task-status-change';

export enum ClientTaskType {
  Unknown = 'Unknown',
  HealthcareAction = 'HealthcareAction',
  SmartGoal = 'SmartGoal',
  HomeBPMonitoring = 'HomeBPMonitoring',
  HomeEducation = 'HomeEducation',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class ClientTaskTypeHelper {
  static valueOf(searchString: string | null): ClientTaskType | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(ClientTaskType).includes(
      searchString as ClientTaskType
    ) //
      ? (searchString as ClientTaskType)
      : null;
  }
}

export enum ClientTaskCategory {
  Unknown = 'Unknown',
  PrimaryCareAgenda = 'PrimaryCareAgenda',
  Labs = 'Labs',
  Vaccines = 'Vaccines',
  Movement = 'Movement',
  Nutrition = 'Nutrition',
  Stress = 'Stress',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class ClientTaskCategoryHelper {
  static valueOf(searchString: string | null): ClientTaskCategory | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(ClientTaskCategory).includes(
      searchString as ClientTaskCategory
    ) //
      ? (searchString as ClientTaskCategory)
      : null;
  }
}

/// ClientTask records are maintained in a subcollection on CarePlan.
///* Due to a Firestore limitation, when working with subcollections, for queries to work we must also store
///* the Firestore document id within the document itself (stored as "modelId").
export interface IClientTaskData {
  modelId: string;
  clientId: string;
  carePlanId: string;
  clientTaskBaseId: string;
  placeBasedCareProvId: string;
  taskType: ClientTaskType;
  taskCategory: ClientTaskCategory;
  name: string;
  description: string;
  frequencyCount: number;
  frequencyPeriod: FreqPeriod;
  expectedCount: number;
  creatorId: string;
  creatorType: UserType;
  createdDate: Date | null;
  dueDate: Date | null;
  taskCompletionDates: (Date | null)[]
  statusChanges: TaskStatusChange[];
}

export class ClientTask {
  modelId: string;
  clientId: string;
  carePlanId: string;
  clientTaskBaseId: string;
  placeBasedCareProvId: string;
  taskType: ClientTaskType;
  taskCategory: ClientTaskCategory;
  name: string;
  description: string;
  frequencyCount: number;
  frequencyPeriod: FreqPeriod;
  expectedCount: number;
  creatorId: string;
  creatorType: UserType;
  createdDate: Date | null;
  dueDate: Date | null;
  taskCompletionDates: (Date | null)[]
  statusChanges: TaskStatusChange[];

  constructor({
    modelId,
    clientId,
    carePlanId,
    clientTaskBaseId,
    placeBasedCareProvId,
    taskType,
    taskCategory,
    name,
    description,
    frequencyCount,
    frequencyPeriod,
    expectedCount,
    creatorId,
    creatorType,
    createdDate,
    dueDate,
    taskCompletionDates,
    statusChanges,
  }: IClientTaskData) {
    this.modelId = modelId;
    this.clientId = clientId;
    this.carePlanId = carePlanId;
    this.clientTaskBaseId = clientTaskBaseId;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.taskType = taskType;
    this.taskCategory = taskCategory;
    this.name = name;
    this.description = description;
    this.frequencyCount = frequencyCount;
    this.frequencyPeriod = frequencyPeriod;
    this.expectedCount = expectedCount;
    this.creatorId = creatorId;
    this.creatorType = creatorType;
    this.createdDate = createdDate;
    this.dueDate = dueDate;
    this.taskCompletionDates = taskCompletionDates;
    this.statusChanges = statusChanges;
  }

  get isComplete(): boolean {
    return this.taskCompletionDates.length >= this.expectedCount;
  }
  
  async updateDb(): Promise<void> {
    const db = new ClientTaskService();
    await db.updateTask(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): ClientTask {
    const data = docSnap.data() as { [key: string]: any };
    return ClientTask.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): ClientTask {
    return new ClientTask({
      modelId: data["modelId"] ?? id, //  with subcollections we prefer to use the id that is stored within the document itself
      clientId: data["clientId"] ?? "",
      carePlanId: data["carePlanId"] ?? "",
      clientTaskBaseId: data["clientTaskBaseId"] ?? "",
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      taskType: ClientTaskTypeHelper.valueOf(data["taskType"] ?? "") ?? ClientTaskType.Unknown,
      taskCategory: ClientTaskCategoryHelper.valueOf(data["taskCategory"] ?? "") ?? ClientTaskCategory.Unknown,
      name: data["name"] ?? "",
      description: data["description"] ?? "",
      frequencyCount: data["frequencyCount"] ?? 0,
      frequencyPeriod: FreqPeriodHelper.valueOf(data["frequencyPeriod"] ?? "") ?? FreqPeriod.Unknown,
      expectedCount: data["expectedCount"] ?? 0,
      creatorId: data["creatorId"] ?? "",
      creatorType: UserTypeHelper.valueOf(data["creatorType"] ?? "") ?? UserType.Unknown,
      createdDate: data["createdDate"] ? new Date(data["createdDate"]) : null,
      dueDate: data["dueDate"] ? new Date(data["dueDate"]) : null,
      taskCompletionDates: data["taskCompletionDates"] 
        ? data["taskCompletionDates"].map((element: string) => new Date(element))
        : [],
      statusChanges: data["statusChanges"] //
        ? data["statusChanges"].map((element: any) => TaskStatusChange.fromMap(element))
        : [],
    });
  }

  toJson(): { [key: string]: any } {
    return {
      "modelId": this.modelId, // with subcollections we must save ID within the Firestore document itself
      "clientId": this.clientId,
      "carePlanId": this.carePlanId,
      "clientTaskBaseId": this.clientTaskBaseId,
      "placeBasedCareProvId": this.placeBasedCareProvId,
      "taskType": this.taskType,
      "taskCategory": this.taskCategory,
      "name": this.name,
      "description": this.description,
      "frequencyCount": this.frequencyCount,
      "frequencyPeriod": this.frequencyPeriod,
      "expectedCount": this.expectedCount,
      "creatorId": this.creatorId,
      "creatorType": this.creatorType,
      "createdDate": this.createdDate?.toISOString(),
      "dueDate": this.dueDate?.toISOString(),
      "taskCompletionDates": this.taskCompletionDates.map(element => element?.toISOString()),
      "statusChanges": this.statusChanges.map((element: TaskStatusChange) => element.toJson()),
    };
  }
}
