import React from 'react';
import useAuthentication from '@/hooks/useAuthentication';
import { Button } from '@chakra-ui/react';

const Home = () => {
  const { logout } = useAuthentication();
  return (
    <div>
      Home
      <Button onClick={logout}>Log Out</Button>
    </div>
  );
};

export default Home;
