import { DocumentSnapshot } from 'firebase/firestore';

import { BillableEventService } from '../services/billable-event-service';

export enum BillableEventType {
  Unknown = 'Unknown',
  Screening = 'Screening',
  RxConsult = 'RxConsult',
  InternalDiscussion = 'InternalDiscussion',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class BillableEventTypeHelper {
  static valueOf(searchString: string | null): BillableEventType | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(BillableEventType).includes(searchString as BillableEventType)) //
      ? searchString as BillableEventType
      : null;
  }
}

export interface IBillableEventData {
  id: string;
  appointmentId: string;
  placeBasedCareProvId: string;
  clientId: string;
  date: Date | null;
  eventType: BillableEventType;
}

export class BillableEvent {
  id: string;
  appointmentId: string;
  placeBasedCareProvId: string;
  clientId: string;
  date: Date | null;
  eventType: BillableEventType;

  constructor({
    id,
    appointmentId,
    placeBasedCareProvId,
    clientId,
    date,
    eventType,
  }: IBillableEventData) {
    this.id = id;
    this.appointmentId = appointmentId;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.clientId = clientId;
    this.date = date;
    this.eventType = eventType;
  }

  async updateDb(): Promise<void> {
    const db = new BillableEventService();
    await db.updateBillableEvent(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): BillableEvent {
    const data = docSnap.data() as { [key: string]: any };
    return BillableEvent.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): BillableEvent {
    return new BillableEvent({
      id: id,
      appointmentId: data["appointmentId"] ?? "",
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      clientId: data["clientId"] ?? "",
      date: (data["date"]) ? new Date(data["date"]) : null,
      eventType: BillableEventTypeHelper.valueOf(data["eventType"] ?? "") ?? BillableEventType.Unknown,

    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      "appointmentId": this.appointmentId,
      "placeBasedCareProvId": this.placeBasedCareProvId,
      "clientId": this.clientId,
      "date": this.date?.toISOString(),
      "eventType": this.eventType,
    };
  }
}
