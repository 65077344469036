import {
  collectionGroup,
  collection,
  DocumentData,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  Query,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { ClientNote } from '../models/client-note';
import { GaLog } from '../utils/ga-log';

export class ClientNoteService {
  private _parentCollectionName: string;
  private _collectionName: string;
  private _collectionGroup: Query<DocumentData, DocumentData>;

  constructor() {
    this._parentCollectionName = 'clients';
    this._collectionName = 'clientNotes';
    this._collectionGroup = collectionGroup(Database, this._collectionName);
  }

  // Get all client notes for a specified client
  async getAllClientNotes(clientId: string): Promise<ClientNote[]> {
    const path = `${this._parentCollectionName}/${clientId}/${this._collectionName}`;
    try {
      const notesQuery = query(
        collection(Database, path),
      );  
      const qSnapshot = await getDocs(notesQuery);
      GaLog.readCollection(path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => ClientNote.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(path, error);
      throw error;
    }
  }

  async getClientNote(clientNoteId: string): Promise<ClientNote> {
    try{
      const noteQuery = query(
        this._collectionGroup,
        where('modelId' ,'==', clientNoteId),
      );
      const qSnapshot = await getDocs(noteQuery);
      if (qSnapshot.empty) {
        throw new Error(`ClientNote not found: ${clientNoteId}`);
      }
      const note = ClientNote.fromFirestore(qSnapshot.docs[0]);
      GaLog.readDocument(`${this._parentCollectionName}/${note.clientId}/${this._collectionName}`, note.modelId);
      return note;
    } catch (error) {
      GaLog.readError(`${this._parentCollectionName}/?????/${this._collectionName}`, error);
      throw error;
    }
  }

  async addClientNote(clientNote: ClientNote): Promise<string> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${clientNote.clientId}/${this._collectionName}`
    );
    try {
        const docRef = await addDoc(subcollectionRef, clientNote.toJson());
        GaLog.addDocument(subcollectionRef.path, docRef.id);
        return docRef.id;
    } catch (error) {
      GaLog.addError(subcollectionRef.path, error);
      throw error;
    }
  }

  async updateClientNote(clientNote: ClientNote): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${clientNote.clientId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, clientNote.modelId);
      await updateDoc(docRef, clientNote.toJson());
      GaLog.updateDocument(subcollectionRef.path, docRef.id);
      return;
    } catch (error) {
      GaLog.updateError(subcollectionRef.path, error);
      throw error;
    }
  }

  // // Appointment Notes can only be updated, not deleted
  // async deleteClientNote(clientNote: ClientNote): Promise<void> {
  //   const subcollectionRef = collection(
  //     Database,
  //     `${this._parentCollectionName}/${clientNote.clientId}/${this._collectionName}`
  //   );
  //   try {
  //     const docRef = doc(subcollectionRef, clientNote.modelId);
  //     await deleteDoc(docRef);
  //     GaLog.deleteDocument(subcollectionRef.path, clientNote.modelId);
  //     return;
  //   } catch (error) {
  //     GaLog.deleteError(subcollectionRef.path, error);
  //     throw error;
  //   }
  // } 
}
