export interface IAppInfoData {
  version: string;
  buildNumber: string;
  upgradeDate: Date | null;
}

export class AppInfo {
  version: string;
  buildNumber: string;
  upgradeDate: Date | null;

  constructor({ version, buildNumber, upgradeDate }: IAppInfoData) {
    this.version = version;
    this.buildNumber = buildNumber;
    this.upgradeDate = upgradeDate;
  }

  static fromMap(data: { [key: string]: any }): AppInfo {
    return new AppInfo({
      version: data["version"] ?? "",
      buildNumber: data["buildNumber"] ?? "",
      upgradeDate: data["upgradeDate"] ? new Date(data["upgradeDate"]) : null,
    });
  }
  toJson(): { [key: string]: any } {
    return {
      "version": this.version,
      "buildNumber": this.buildNumber,
      "upgradeDate": this.upgradeDate?.toISOString(),
    };
  }
}
