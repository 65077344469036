import { useState, useEffect } from 'react';
import { ClientUserService } from '@oben-core-web/services/client-user-service';
import { ClientUser } from '@oben-core-web/models/client-user';

const useClientUser = (clientUserId?: string) => {
  const [clientUser, setClientUser] = useState<ClientUser>();
  useEffect(() => {
    let unsubscribeFunc: (() => void) | undefined;
    if (clientUserId) {
      const clientUserService = new ClientUserService();
      const { unsubscribe } = clientUserService.streamClientUser(
        clientUserId,
        (snap) => {
          if (snap.exists()) {
            setClientUser(ClientUser.fromFirestore(snap));
          }
        }
      );
      unsubscribeFunc = unsubscribe;
    }
    return () => {
      unsubscribeFunc && unsubscribeFunc();
    };
  }, [clientUserId]);
  return { clientUser };
};

export default useClientUser;
