export interface IAddressData {
    street1: string | null;
    street2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
}

export class Address {
    street1: string | null;
    street2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;

    constructor({
        street1,
        street2,
        city,
        state,
        zip,
    }: IAddressData) {
        this.street1 = street1;
        this.street2 = street2;
        this.city = city;
        this.state = state;
        this.zip = zip;
    }

    get completeAddress(): string {
        const parts: (string | null)[] = [
          this.street1,
          this.street2,
          this.city,
        ];
    
        // Add state and zip as a combined part if either is present
        if ((this.state && this.state.trim()) || (this.zip && this.zip.trim())) {
          const stateZip = [this.state, this.zip]
            .filter(part => part && part.trim())
            .join(' ');
          parts.push(stateZip);
        }
    
        // Remove null or empty parts
        const filteredParts = parts.filter(part => part && part.trim());
    
        // Join remaining parts with a comma
        return filteredParts.join(', ');
    }

    static fromMap(data: { [key: string]: any }): Address {
        return new Address({
            street1: data["street1"] ?? null,
            street2: data["street2"] ?? null,
            city: data["city"] ?? null,
            state: data["state"] ?? null,
            zip: data["zip"] ?? null,
        });
    }

    toJson(): { [key: string]: any } {
        return {
            "street1": this.street1,
            "street2": this.street2,
            "city": this.city,
            "state": this.state,
            "zip": this.zip,
        };
    }
}