import { DocumentSnapshot } from 'firebase/firestore';

import { Color } from '../constants/color'; 
import { UserType, UserTypeHelper, BpRange, BpRangeHelper, bpRangeData } from '../constants/core-enums';
import { BpReadingService } from '../services/bp-reading-service';

export interface iBpReadingData {
  id: string;
  clientId: string; // can be either ScreeningClientId or ClientUserId
  systolic: number;
  diastolic: number;
  heartRate: number;
  readingDate: Date;
  recorderType: UserType;
  recorderId: string;
  locationId: string;
  isScreening: boolean; // indicates if id is ScreeningClient (rather than ClientUser)
  isInvalid: boolean; // flag for whether reading is valid or not
  invalidDate?: Date | null; // time that reading was flagged as invalid
  invalidatorType?: UserType | null;
  invalidatorId?: string | null; // userId of person that flagged it as invalid
}

export class BpReading {
  id: string;
  clientId: string; // can be either ScreeningClientId or ClientUserId
  systolic: number;
  diastolic: number;
  heartRate: number;
  readingDate: Date;
  recorderType: UserType;
  recorderId: string;
  locationId: string;
  isScreening: boolean; // indicates if id is ScreeningClient (rather than ClientUser)
  isInvalid: boolean; // flag for whether reading is valid or not
  invalidDate?: Date | null; // time that reading was flagged as invalid
  invalidatorType?: UserType | null;
  invalidatorId?: string | null; // userId of person that flagged it as invalid

  constructor({
    id,
    clientId,
    systolic,
    diastolic,
    heartRate,
    readingDate,
    recorderType,
    recorderId,
    locationId,
    isScreening = false,
    isInvalid = false,
    invalidDate,
    invalidatorType,
    invalidatorId,
  }: iBpReadingData) {
    this.id = id;
    this.clientId = clientId;
    this.systolic = systolic;
    this.diastolic = diastolic;
    this.heartRate = heartRate;
    this.readingDate = readingDate;
    this.recorderType = recorderType;
    this.recorderId = recorderId;
    this.locationId = locationId;
    this.isScreening = isScreening;
    this.isInvalid = isInvalid;
    this.invalidDate = invalidDate;
    this.invalidatorType = invalidatorType;
    this.invalidatorId = invalidatorId;
  }

  // getters
  get range(): BpRange {
    return BpRangeHelper.lookup(this.systolic, this.diastolic) ?? BpRange.Error;
  }

  get label(): string {
    return bpRangeData[this.range].label;
  }

  get stage(): string {
    return bpRangeData[this.range].stage;
  }

  get color(): Color {
    return bpRangeData[this.range].textColor;
  }

  get wasToday(): boolean {
    const todayDate = new Date();
    return (
      this.readingDate?.getDate() === todayDate.getDate() &&
      this.readingDate?.getMonth() === todayDate.getMonth() &&
      this.readingDate?.getFullYear() === todayDate.getFullYear()
    );
  }

  get wasYesterday(): boolean {
    const now = new Date();
    const todayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterdayDate = new Date(todayDate.setDate(todayDate.getDate() - 1));
    return (
      this.readingDate.getDate() === yesterdayDate.getDate() &&
      this.readingDate.getMonth() === yesterdayDate.getMonth() &&
      this.readingDate.getFullYear() === yesterdayDate.getFullYear()
    );
  }

  //
  // Misc helper methods
  //
  async updateDb(): Promise<void> {
    const db = new BpReadingService();
    await db.updateBpReading(this);
  }

  // Factories
  //
  static fromFirestore(doc: DocumentSnapshot): BpReading {
    const data = doc.data() as { [key: string]: any };
    return BpReading.fromMap(doc.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): BpReading {
    return new BpReading({
      id: id,
      clientId: data["clientId"] ?? "",
      systolic: data["systolic"] ?? 0,
      diastolic: data["diastolic"] ?? 0,
      heartRate: data["heartRate"] ?? 0,
      readingDate: data["readingDate"] ? new Date(data["readingDate"]) : new Date(1, 0, 1), // default to 1/1/0001
      recorderType: UserTypeHelper.valueOf(data["recorderType"] ?? "") ?? UserType.Unknown,
      recorderId: data["recorderId"] ?? "",
      locationId: data["locationId"] ?? "",
      isScreening: data["isScreening"] ?? false,
      isInvalid: data["isInvalid"] ?? false,
      invalidDate: data["invalidDate"] ? new Date(data["invalidDate"]) : null,
      invalidatorType: UserTypeHelper.valueOf(data["invalidatorType"] ?? "") ?? null,
      invalidatorId: data["invalidatorId"] ?? null,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      "clientId": this.clientId,
      "systolic": this.systolic,
      "diastolic": this.diastolic,
      "heartRate": this.heartRate,
      "readingDate": this.readingDate.toISOString(),
      "recorderType": this.recorderType,
      "recorderId": this.recorderId,
      "locationId": this.locationId,
      "isScreening": this.isScreening,
      "isInvalid": this.isInvalid,
      "invalidDate": this.invalidDate?.toISOString(),
      "invalidatorType": this.invalidatorType,
      "invalidatorId": this.invalidatorId,
    };
  }
}