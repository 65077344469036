/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO: remove above es-lint rule if/when we remove unused functions

import {
  CollectionReference,
  collection,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { ClientUser } from '../models/client-user';
import { GaLog } from '../utils/ga-log';

export class ClientUserService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = 'clients';
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get a stream of a single ClientUser's profile
  streamClientUser(
    uid: string,
    handleSnapshot: (docSnap: DocumentSnapshot) => void = (
      docSnap: DocumentSnapshot
    ) => {
      if (docSnap.exists()) {
        return ClientUser.fromFirestore(docSnap);
      } else {
        return ClientUser.fromMap('', {});
      }
    }
  ): {
    unsubscribe: (() => void) | undefined;
  } {
    let unsubscribe: (() => void) | undefined;
    try {
      if (uid) {
        const docRef = doc(this._collectionReference, uid);
        unsubscribe = onSnapshot(
          docRef, 
          (docSnap) => {
            handleSnapshot(docSnap);
            GaLog.readDocument(this._collectionReference.path, docSnap.id, {isSubscription: true});
          },
          (error) => {
            console.error('Error in onSnapshot:', error);
            // TODO: Do we need to throw an error here?
            GaLog.readDocument(this._collectionReference.path, uid, {isSubscription: true});
          }
        );
      }
      return { unsubscribe };
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error, {isSubscription: true});
      throw error;
    }
  }

  // TODO: include filter on ProgramId or some other identifier so that teams don't have visibility into staff in other programs
  async getAllClientUsers(): Promise<ClientUser[]> {
    try {
      const qSnapshot = await getDocs(this._collectionReference);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => ClientUser.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // confirm that a ClientUser exists
  async validateClientUser(uid: string): Promise<boolean> {
    try {
      const docRef = doc(this._collectionReference, uid);
      const docSnap = await getDoc(docRef);
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return docSnap.exists();
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getClientUser(uid: string): Promise<ClientUser> {
    try {
      const docRef = doc(this._collectionReference, uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`Client User not found: ${uid}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return ClientUser.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addClientUser(clientUser: ClientUser): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, clientUser.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateClientUser(clientUser: ClientUser): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, clientUser.uid);
      await updateDoc(docRef, clientUser.toJson());
      GaLog.updateDocument(this._collectionReference.path, clientUser.uid);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  async deleteClientUser(uid: string): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, uid);
      await deleteDoc(docRef);
      GaLog.deleteDocument(this._collectionReference.path, uid);
      return;
    } catch (error) {
      GaLog.deleteError(this._collectionReference.path, error);
      throw error;
    }
  }

  //   // not used by web portal
  // async lookupByNameDob(lastName: string, dob: Date): Promise<ClientUser[] | any> {
  // }

  //   // not used by web portal
  // async incrementCounter(coachId: string, clientID: string): Promise<void> {
  // }

  //   // not used by web portal
  // async checkIfFollowing(uid: string, forumCollection: string, topicId: string): Promise<any> {
  // }

  //   // not used by web portal
  // async toggleFollowing(uid: string, forumCollection: string, topicId: string): Promise<any> {
  // }

  //   // not used by web portal
  // async saveRecentlyViewedVideo(uid: string, journeyContentId: string): Promise<void> {
  // }
}
