import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Avatar,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
} from '@chakra-ui/react';
// import { RootState } from '@/store'; // Adjust the import path to your store
import useAuthentication from '@/hooks/useAuthentication';
import useCurrentUser from '@/hooks/useCurrentUser';

const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  // const user = useSelector((state: RootState) => state.app.currentUser);
  const { webUser: user } = useCurrentUser();
  const { logout } = useAuthentication();

  const handleLogout = () => {
    logout(); // Ensure this action exists and is imported correctly
  };

  return (
    <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <MenuButton
        as={Box}
        display='flex'
        alignItems='center'
        cursor='pointer'
        flexDir={'row'}
        border={'1px'}
        p={2}
        rounded={8}
        onClick={() => setIsOpen(!isOpen)}
      >
        <HStack>
          {user && <Avatar name={`${user.name?.first} ${user.name?.last}`} />}
          <VStack align='start' ml={2} spacing={0}>
            <Text fontWeight='bold'>
              {user?.name ? `${user.name.first} ${user.name.last}` : 'User'}
            </Text>
            <Text fontSize='sm' color='gray.500'>
              {user?.userType}
            </Text>
          </VStack>
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => navigate(`/profile/${user?.uid}`)}>
          My Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
