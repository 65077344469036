export enum StripePmtStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Failed = 'Failed',
  Success = 'Success',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class StripePmtStatusHelper {
  static valueOf(searchString: string | null): StripePmtStatus | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(StripePmtStatus).includes(searchString as StripePmtStatus)) //
      ? searchString as StripePmtStatus
      : null;
  }
}

export interface IStripePmtStatusChangeData {
  status: StripePmtStatus;
  date: Date;
  details: string;
}

// StripePmtStatusChange records are maintained in a list on Appointment
export class StripePmtStatusChange {
  status: StripePmtStatus;
  date: Date;
  details: string;

  constructor({ 
    status, 
    date, 
    details,
  }: IStripePmtStatusChangeData) {
    this.status = status;
    this.date = date;
    this.details = details;
  }

  static fromMap(data: { [key: string]: any }): StripePmtStatusChange {
    return new StripePmtStatusChange({
      status: StripePmtStatusHelper.valueOf(data["status"] ?? "") ?? StripePmtStatus.Unknown,
      date: data["date"] ? new Date(data["date"]) : new Date(1, 0, 1), // default to 1/1/0001
      details: data["details"] ?? "",
    });
  }

  toJson(): { [key: string]: any } {
    return {
      "status": this.status,
      "date": this.date.toISOString(),
      "details": this.details,
    };
  }
}