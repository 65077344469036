import {
  CollectionReference,
  collection,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { Barbershop } from '../models/barbershop';
import { GaLog } from '../utils/ga-log';

export class BarbershopService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionName = 'barbershops';
    this._collectionReference = collection(Database, this._collectionName);
  }

  // get a stream of a single Barbershop's profile
  streamBarbershop(
    id: string,
    handleSnapshot: (docSnap: DocumentSnapshot) => void = (
      docSnap: DocumentSnapshot
    ) => {
      if (docSnap.exists()) {
        return Barbershop.fromFirestore(docSnap);
      } else {
        return Barbershop.fromMap('', {});
      }
    }
  ): {
    unsubscribe: (() => void) | undefined;
  } {
    let unsubscribe: (() => void) | undefined;
    try {
      if (id) {
        const docRef = doc(this._collectionReference, id);
        unsubscribe = onSnapshot(
          docRef, 
          (docSnap) => {
            handleSnapshot(docSnap);
            GaLog.readDocument(this._collectionReference.path, docSnap.id, {isSubscription: true});
          },
          (error) => {
            console.error('Error in onSnapshot:', error);
            // TODO: Do we need to throw an error here?
            GaLog.readDocument(this._collectionReference.path, id, {isSubscription: true});
          },
        );
      }
      return { unsubscribe };
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error, {isSubscription: true});
      throw error;
    }
  }

  // TODO: include filter on ProgramId or some other identifier so that teams don't have visibility into staff in other programs
  async getAllBarbershops(): Promise<Barbershop[]> {
    try {
      const qSnapshot = await getDocs(this._collectionReference);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => Barbershop.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  // confirm that a Barbershop exists
  async validateBarbershop(barbershopId: string): Promise<boolean> {
    try {
      const docRef = doc(this._collectionReference, barbershopId);
      const docSnap = await getDoc(docRef);
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return docSnap.exists();
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async getBarbershop(barbershopId: string): Promise<Barbershop> {
    try {
      const docRef = doc(this._collectionReference, barbershopId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`Barbershop not found: ${barbershopId}`);
      }
      GaLog.readDocument(this._collectionReference.path, docSnap.id);
      return Barbershop.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addBarbershop(barbershop: Barbershop): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, barbershop.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }

  async updateBarbershop(barbershop: Barbershop): Promise<void> {
    try {
      const docRef = doc(this._collectionReference, barbershop.id);
      await updateDoc(docRef, barbershop.toJson());
      GaLog.updateDocument(this._collectionReference.path, barbershop.id);
      return;
    } catch (error) {
      GaLog.updateError(this._collectionReference.path, error);
      throw error;
    }
  }

  // // Barbershops can only be updated, not deleted
  // async deleteBarbershop(barbershopId: string): Promise<void> {
  //   try {
  //     const docRef = doc(this._collectionReference, barbershopId);
  //     await deleteDoc(docRef);
  //     GaLog.deleteDocument(this._collectionReference.path, barbershopId);
  //     return;
  //   } catch (error) {
  //     GaLog.deleteError(this._collectionReference.path, error);
  //     throw error;
  //   }
  // }
}
