import React from 'react';
import {
  Box,
  Avatar,
  Container,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Heading,
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { convertToLocalTZ } from '@lib/timezone'; // Adjust the import path as needed
import { WebUser } from '@oben-core-web/models/web-user'; // Adjust the import path as needed

// interface IWorkingHours {
//   dayOfWeek: number;
//   startTime: Date;
//   endTime: Date;
// }

interface IUserCardProps {
  user: WebUser | null;
  canEdit: boolean;
  onToggleForm: () => void;
}

const UserCard = ({ user, canEdit, onToggleForm }: IUserCardProps) => {
  const daysOfWeek: Record<number, string> = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  };

  return (
    <Box
      minWidth='75%'
      p={4}
      display='flex'
      flexDirection='row'
      borderWidth='1px'
      borderRadius='lg'
      boxShadow='md'
    >
      <Container
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
      >
        <Avatar
          name={user?.name.display as string | undefined}
          size='2xl'
          mr={4}
        />
        <Container display='flex' flexDirection='column'>
          <Heading as='h1'>{user?.name.display}</Heading>
          <Text as='h2' fontStyle='italic'>
            {user?.userType}
          </Text>
          {user?.email && <Text>{user.email}</Text>}
          {user?.phoneNumber && <Text>{user.phoneNumber}</Text>}
        </Container>
      </Container>
      <Container>
        <Container
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Heading as='h3'>Working Hours</Heading>
          {canEdit && <Button onClick={onToggleForm}>Edit</Button>}
        </Container>
        {user?.defaultWorkingHours && (
          <Table variant='simple' width='100%'>
            <Thead>
              <Tr>
                <Td>Day</Td>
                <Td>Start Time</Td>
                <Td>End Time</Td>
              </Tr>
            </Thead>
            <Tbody>
              {user.defaultWorkingHours.map((day) => (
                <Tr key={day.dayOfWeek}>
                  <Td>{daysOfWeek[day.dayOfWeek]}</Td>
                  <Td>
                    {DateTime.fromJSDate(
                      convertToLocalTZ(day.startTime)
                    ).toFormat("h':'mm a")}
                  </Td>
                  <Td>
                    {DateTime.fromJSDate(
                      convertToLocalTZ(day.endTime)
                    ).toFormat("h':'mm a")}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Container>
    </Box>
  );
};

export default UserCard;
