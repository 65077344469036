import {
  collectionGroup,
  collection,
  DocumentData,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  Query,
} from 'firebase/firestore';
import { Database } from '../../firebase';

import { MedAdherence } from '../models/med-adherence';
import { GaLog } from '../utils/ga-log';

export class MedAdherenceService {
  private _grandParentCollectionName: string;
  private _parentCollectionName: string;
  private _collectionName: string;
  private _collectionGroup: Query<DocumentData, DocumentData>;

  constructor() {
    this._grandParentCollectionName = 'clients';
    this._parentCollectionName = 'carePlans';
    this._collectionName = 'medAdherences';
    this._collectionGroup = collectionGroup(Database, this._collectionName);
  }

  // Get all medAdherences for a specified care plan (for an individual client)
  async getClientMedAdherences(clientId: string, carePlanId: string): Promise<MedAdherence[]> {
    const path = `${this._grandParentCollectionName}/${clientId}/${this._parentCollectionName}/${carePlanId}/${this._collectionName}`;
    try {
      const medsQuery = query(
        collection(Database, path),
      );
      const qSnapshot = await getDocs(medsQuery);
      GaLog.readCollection(path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => MedAdherence.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(path, error);
      throw error;
    }
  }

  async getMedAdherence(medAdherenceId: string): Promise<MedAdherence> {
    try {
      const medQuery = query(
        this._collectionGroup,
        where('modelId', '==', medAdherenceId)
      );
      const docs = await getDocs(medQuery);
      if (docs.empty) {
        throw new Error(`MedAdherence not found: ${medAdherenceId}`);
      }
      const medAdherence = MedAdherence.fromFirestore(docs.docs[0]);
      GaLog.readDocument(`${this._grandParentCollectionName}/${medAdherence.clientId}/${this._parentCollectionName}/${medAdherence.carePlanId}/${this._collectionName}`, medAdherence.modelId);
      return medAdherence;
    } catch (error) {
      GaLog.readError(`${this._grandParentCollectionName}/?????/${this._parentCollectionName}/?????/${this._collectionName}`, error);
      throw error;
    }
  }

  async addMedAdherence(medAdherence: MedAdherence): Promise<string> {
    const subcollectionRef = collection(
      Database,
      `${this._grandParentCollectionName}/${medAdherence.clientId}/${this._parentCollectionName}/${medAdherence.carePlanId}/${this._collectionName}`
    );
    try {
      const docRef = await addDoc(subcollectionRef, medAdherence.toJson());
      GaLog.addDocument(subcollectionRef.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(subcollectionRef.path, error);
      throw error;
    }
  }

  async updateMedAdherence(medAdherence: MedAdherence): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._grandParentCollectionName}/${medAdherence.clientId}/${this._parentCollectionName}/${medAdherence.carePlanId}/${this._collectionName}/${medAdherence.modelId}`
    );
    try {
      const docRef = doc(subcollectionRef, medAdherence.modelId);
      await updateDoc(docRef, medAdherence.toJson());
      GaLog.updateDocument(subcollectionRef.path, docRef.id);
      return;
    } catch (error) {
      GaLog.updateError(subcollectionRef.path, error);
      throw error;
    }
  }

  async deleteMedAdherence(medAdherence: MedAdherence): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._grandParentCollectionName}/${medAdherence.clientId}/${this._parentCollectionName}/${medAdherence.carePlanId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, medAdherence.modelId);
      await deleteDoc(docRef);
      GaLog.deleteDocument(subcollectionRef.path, medAdherence.modelId);
      return;
    } catch (error) {
      GaLog.deleteError(subcollectionRef.path, error);
      throw error;
    }
  }
}
